import React from "react";
import { Layout } from "antd";
import Footer from "components/layout-components/Footer";
import StarterService from "services/StarterService";
import Loading from "components/shared-components/Loading";
import { useHistory } from "react-router-dom";
import { INITIAL_SCREEN } from "configs/AppConfig";
import AuthService from "services/AuthService";
import { TSDB_TOKEN_PATH } from "auth/TSDBInterceptor";

const Starter = (props) => {
    let history = useHistory();

    let params = new URLSearchParams(props.location.search)
    let accountId = params.get("accountId")
    let starterKey = params.get("starterKey")

    if (starterKey) {
        AuthService.logout().then(() => {
            localStorage.removeItem("auth_token")
            localStorage.removeItem("refresh_token")
            localStorage.removeItem("account_id")
            localStorage.removeItem(TSDB_TOKEN_PATH)

            StarterService.getCredentials({ account_id: accountId, starter_key: starterKey }).then(() => {
                localStorage.setItem("account_id", accountId)
                setTimeout(() => {
                    history.push(INITIAL_SCREEN);
                }, 1000);
            }).catch(() => {
                history.push("/auth/login")
            })
        })
    }

    return (
        <Layout>
            <div className="h-100">
                <div className="container d-flex flex-column h-100">
                    <div className="app-content">
                        <div>
                           <Loading cover="content" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Layout>
    )
};

export default Starter;
