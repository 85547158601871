import fetch from "auth/FetchInterceptor";
const PerfmonService = {};

PerfmonService.get = function (accountId, monitorUuid) {
  return fetch({
    url: `/account/${accountId}/performance-monitors/${monitorUuid}`,
    method: "get",
  });
};

PerfmonService.getPerformanceMonitors = function (accountId) {
  return fetch({
    url: `/account/${accountId}/performance-monitors/`,
    method: "get",
  });
};

PerfmonService.getPerformanceMonitorTimeseries = function (
  accountId,
  monitorUuid,
  timerange
) {
  return fetch({
    url: `/account/${accountId}/performance-monitors/${monitorUuid}/timeseries`,
    method: "get",
    params: {
      date_from: timerange.isodate_from,
      date_to: timerange.isodate_to,
    },
  });
};

PerfmonService.create = function (accountId, data) {
  return fetch({
    url: `/account/${accountId}/performance-monitors/`,
    method: "post",
    data: data,
  });
};

PerfmonService.update = function (accountId, monitorUuid, data) {
  return fetch({
    url: `/account/${accountId}/performance-monitors/${monitorUuid}/`,
    method: "put",
    data: data,
  });
};


PerfmonService.deletePerformanceMonitor = function (accountId, monitorUuid) {
  return fetch({
    url: `/account/${accountId}/performance-monitors/${monitorUuid}/`,
    method: "delete",
    data: { run_in_background: true },
  });
};

PerfmonService.getPerformanceMonitorDomains = function (
  accountId,
  monitorUuid,
  timerange
) {
  return fetch({
    url: `/account/${accountId}/performance-monitors/${monitorUuid}/domains`,
    method: "get",
    params: {
      date_from: timerange.isodate_from,
      date_to: timerange.isodate_to,
    },
  });
};

PerfmonService.getPerfmonCheckDetails = function (accountId, monitorUuid) {
  return fetch({
    url: `/account/${accountId}/performance-monitors/${monitorUuid}/static`,
    method: "get",
  });
};

PerfmonService.pause = function (accountId, monitorUuid) {
  return fetch({
    url: `/account/${accountId}/performance-monitors/${monitorUuid}/`,
    method: "patch",
    data: { active: false }
  });
};

PerfmonService.unpause = function (accountId, monitorUuid) {
  return fetch({
    url: `/account/${accountId}/performance-monitors/${monitorUuid}/`,
    method: "patch",
    data: { active: true }
  });
};

PerfmonService.getLighthouseReport = function (accountId, monitorUuid, timestamp) {
  return fetch({
    url: `/account/${accountId}/performance-monitors/${monitorUuid}/report?timestamp=${timestamp}`,
    method: "get"
  });
}


export default PerfmonService;
