import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Loading from "components/shared-components/Loading";

export const ErrorViews = ({ match }) => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route
          path={`${match.url}/404`}
          component={lazy(() => import(`./general-error`))}
        />
        <Route
          path={`${match.url}/`}
          component={lazy(() => import(`./general-error`))}
        />
      </Switch>
    </Suspense>
  );
};

export default ErrorViews;
