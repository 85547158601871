import { PERFMON_CHECK_DETAILS_RETRIEVED } from "../constants/Perfmon";

import {
  ACCOUNT_SWITCHED
} from "../constants/Account"

const initState = {
  perfmonCheckDetails: {},
  perfmons: [],
  controllerInitialized: false
};

const perfmon = (state = initState, action) => {
  switch (action.type) {
    case "PERFMON_CONTROLLER_INITIALIZED":
      return {
        ...state,
        controllerInitialized: true
      }
    case "PERFMON_CONTROLLER_UPDATED":
      return {
        ...state,
        [`${action.monitorUuid}_controller`]: action.controller
      }
    case "PERFMONS_CONTROLLER_UPDATED":
      return {
        ...state,
        perfmonsController: action.perfmonsController
      }
    case "PERFMONS_RETRIEVED":
      return {
        ...state,
        perfmons: action.perfmons
      }
    case ACCOUNT_SWITCHED:
      return initState
    case PERFMON_CHECK_DETAILS_RETRIEVED:
      let perfmonCheckDetails = JSON.parse(
        JSON.stringify(state.perfmonCheckDetails)
      );

      if (perfmonCheckDetails[action.monitorUuid] === undefined) {
        perfmonCheckDetails[action.monitorUuid] = {};
      }

      perfmonCheckDetails[action.monitorUuid] = action.data;
      return {
        ...state,
        perfmonCheckDetails: perfmonCheckDetails,
      };
    default:
      return state;
  }
};

export default perfmon;
