import { LOCATION_METADATA } from "utils/Location";
import { COLOR_OK, COLOR_WARN, COLOR_CRIT } from "constants/ColorConstant";
import { GetCritWarnGradient } from "./ChartBuilder";
import Utils from "utils";
import { Transformer } from "./Transform";
import { MetricConfig } from "controllers/TSDBController";

export interface ITimeserie {
    series: Serie[]
    time_config: TimeserieTimeconfig
}

export type TimeserieTimeconfig = {
    date_from: string
    date_to: string
    aggregation: string | null
    retention_name: string | null
}

export type GradientStep = [number, string]
export type GradientSteps = Array<GradientStep>
export const DefaultGradientSteps:GradientSteps = [
    [0, "#D9EAF8"],
    [1, "#95CDF8"]
]

export type SeriePoint = [number, number, { [index: string]: string }]
export type DataPoint = {
    x: number,
    y: number,
    labels: {[index: string]: string}
}

export type SerieData = Array<SeriePoint>
export type Serie = {
    name: string
    data: SerieData
}

export interface FilterMap {
    [filterName: string]: string[];
}

export function sumSeriePoints(points: SeriePoint[]): number {
    let sum = 0;
    for (const [, value] of points) {
        sum += value;
    }
    return sum;
}

export function avgSeriePoints(points: SeriePoint[]): number {
    let sum = 0;
    let count = 0;
    for (const [, value] of points) {
        sum += value;
        count += 1
    }
    return sum / count;
}

export function sumByLabel(points: SeriePoint[], label: string): string[] {
    const values = new Set<string>();
    for (const [, , labels] of points) {
        if (label in labels) {
            values.add(labels[label]);
        }
    }
    return Array.from(values);
}

export class Dataset {
    name: string
    srcData: SerieData
    filteredData: SerieData
    filtered: boolean
    label: string
    backgroundColor: string | (any)
    borderColor: string | (any)
    pointBorderWidth: number
    pointHoverRadius: number
    pointHoverBorderWidth: number
    pointRadius: number
    borderWidth: number
    tension: number
    fill: boolean
    hidden: boolean = false

    metricConfig: null | MetricConfig = null

    pointBorderColor: string
    fillColor: string | (any)
    pointBackgroundColor: string
    pointHoverBackgroundColor: string
    pointHoverBorderColor: string

    averagedByTimestamp: false
    barThickness: number

    constructor(name: string, srcData: SerieData) {
        this.name = name
        this.srcData = srcData
        
        this.filteredData = []
        this.filtered = false

        // Defaults
        this.label = ""
        this.backgroundColor = "#000000"
        this.borderColor = "#000000"
        this.fillColor = "#000000"
        this.pointBorderColor = "#000000"
        this.pointBackgroundColor = "#000000"
        this.pointHoverBackgroundColor = "#000000"
        this.pointHoverBorderColor = "#000000"
        this.pointBorderWidth = 1
        this.pointHoverRadius = 1
        this.pointHoverBorderWidth = 1
        this.pointRadius = 2
        this.borderWidth = 2
        this.tension = 0.4
        this.fill = false
        this.averagedByTimestamp = false
        this.barThickness = 8
    }

    averageByTimestamp = () => {
        const seriesMap = new Map<number, { sum: number, count: number }>();
        for (const [timestamp, value] of this.filteredData) {
            if (seriesMap.has(timestamp)) {
                const { sum, count } = seriesMap.get(timestamp)!;
                seriesMap.set(timestamp, { sum: sum + value, count: count + 1 });
            } else {
                seriesMap.set(timestamp, { sum: value, count: 1 });
            }
        }

        const result: SeriePoint[] = [];

        const seriesMapIterator = seriesMap.entries();
        let next = seriesMapIterator.next();
        while (!next.done) {
            const [timestamp, aggregate] = next.value;
            const { sum, count } = aggregate;
            result.push([timestamp, sum / count, {}]);
            next = seriesMapIterator.next();
        }

        return result;
    }


    applyFilters = (filterMap: FilterMap) => {
        let filteredData = this.srcData.filter(point => {
            const labels = point[2];
            return Object.keys(filterMap).every(key => {
                const filterValues = filterMap[key];
                if (!filterValues) {
                    return []
                }
                if (filterValues.length === 0) return true;
                return filterValues.some(value => labels[key] === value);
            });
        });

        let newDataset = new Dataset(this.name, [])
        newDataset.filteredData = filteredData
        newDataset.filtered = true
        newDataset.srcData = filteredData
        return newDataset
    };

    getData = ():SerieData => {
        let outputData;

        if (this.filtered) {
            outputData = this.filteredData
        } else {
            outputData = this.srcData
        }
        
        if (this.averagedByTimestamp) {
            outputData = this.averageByTimestamp()
        }

        if (this.metricConfig !== null) {
            return this.format(outputData)
        }
    
        return outputData
    }

    withMetricConfig = (mc: MetricConfig) => {
        if (mc === undefined) {
            return this
        }
        this.metricConfig = mc
        return this
    }

    withCritWarnGradient = (criticalThreshold: number, warningThreshold: number, direction: string) => {
        let max = this.max()

        if (max === null) {
            return this
        }

        this.borderColor = function(context:any) {
            // @ts-ignore
            return GetCritWarnGradient(context, criticalThreshold, warningThreshold, direction)
        }
        this.pointBorderColor = this.borderColor
        this.pointBackgroundColor = this.borderColor
        this.pointHoverBackgroundColor = this.borderColor
        this.pointHoverBorderColor = this.borderColor
        this.backgroundColor = this.borderColor

        return this
    }

    withPerfmonGradientStroke = () => {
        let max = this.max()

        let name = this.name;
    
        this.borderColor = function(context:any) {
            const chart = context.chart;
            const { ctx, chartArea } = chart;
            
            if (!chartArea) {
                // This case happens on initial chart load
                return null;
            }
            
            let width:any, height:any, gradient:any = null;
            
            let getGradient = (ctx:any, chartArea:any) => {
                const chartWidth = chartArea.right - chartArea.left;
                const chartHeight = chartArea.bottom - chartArea.top;
                if (gradient === null || width !== chartWidth || height !== chartHeight) {
                    // Create the gradient because this is either the first render
                    // or the size of the chart has changed
                    width = chartWidth;
                    height = chartHeight;
    
                    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);

                    if (max === null) {
                        return
                    }

                    if (name === "Largest Contentful Paint") {
                        let v = 4000 / max
                        if (v < 1) {
                            gradient.addColorStop(v, COLOR_CRIT);
                        }

                        v = 2500 / max
                        if (v < 1) {
                            gradient.addColorStop(v, COLOR_WARN);
                        }

                        gradient.addColorStop(0, COLOR_OK);
                    } else if (name === "Cumulative Layout Shift") {
                        let v = 0.25 / max
                        if (v < 1) {
                            gradient.addColorStop(v, COLOR_CRIT);
                        }

                        v = 0.1 / max
                        if (v < 1) {
                            gradient.addColorStop(v, COLOR_WARN);
                        }

                        gradient.addColorStop(0, COLOR_OK);
                    } else if (name === "First Input Delay") {
                        let v = 300 / max
                        if (v < 1) {
                            gradient.addColorStop(v, COLOR_CRIT);
                        }

                        v = 100 / max
                        if (v < 1) {
                            gradient.addColorStop(v, COLOR_WARN);
                        }

                        gradient.addColorStop(0, COLOR_OK);
                    } else if (name === "First Contentful Paint") {
                        let v = 3000 / max
                        if (v < 1) {
                            gradient.addColorStop(v, COLOR_CRIT);
                        }

                        v = 1800 / max
                        if (v < 1) {
                            gradient.addColorStop(v, COLOR_WARN);
                        }

                        gradient.addColorStop(0, COLOR_OK);
                    } else {
                        gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
                        gradient.addColorStop(0, COLOR_OK);
                        gradient.addColorStop(0.5, COLOR_WARN);
                        gradient.addColorStop(1, COLOR_CRIT);
                    }
                }

                return gradient
            }

            return getGradient(ctx, chartArea);
        }

        return this
    }

    alias = (alias: string) => {
        this.label = alias
        return this
    }

    randomWalk = (min: number, max: number) => {
        const FifteenMinutesMS = 900000
        const NumberOfPoints = 100

        let newData:SerieData = []
        let labels = {"location": "EU-1"}
        let now = Date.now()

        let currentPointTs = now - NumberOfPoints * FifteenMinutesMS

        for (let i = 1; i <= NumberOfPoints; ++i) {
            newData.push([currentPointTs + 900000, Utils.generateRandom(min, max), labels])
            currentPointTs += FifteenMinutesMS
        }
        this.srcData = newData
        return this
    }

    min = () => {
        let data = this.getData();
        if (data.length === 0) {
            return null
        }
        let minValue = data[0][1]
        this.getData().forEach((point) => {
            // @ts-ignore
            if (point[1] < minValue) {
                minValue = point[1]
            }
        })
        return minValue
    }

    max = () => {
        let data = this.getData();
        if (data.length === 0) {
            return null
        }
        let maxValue = data[0][1]
        this.getData().forEach((point) => {
            // @ts-ignore
            if (point[1] > maxValue) {
                maxValue = point[1]
            }
        })
        return maxValue
    }

    avg = () => {
        let data = this.getData();
        let sum = 0;
        let count = 0;
        for (const [, value] of data) {
            sum += value;
            count += 1
        }
        return sum / count;
    }

    withProp = (name: string, value: number | string | boolean | (any)) => {
        // @ts-ignore
        this[name] = value
        return this
    }

    withGradientStroke = (steps: GradientSteps) => {
        this.borderColor = function (context: any) {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            if (!chartArea) {
                // This case happens on initial chart load
                return null;
            }

            let gradient: any = null;

            gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
            steps.forEach((step: GradientStep) => (
                gradient.addColorStop(step[0], step[1])
            ))

            return gradient
        }

        return this
    }

    withGradientFill = (steps: GradientSteps) => {
        this.backgroundColor = (context:any) => {
            const chart = context.chart;
            const { ctx, chartArea } = chart;
            if (!chartArea) {
                // This case happens on initial chart load
                return null;
            }
            let gradient: any = null;

            gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
            steps.forEach((step: GradientStep) => {
                gradient.addColorStop(step[0], step[1])
            })
            return gradient
        }
        this.fill = true
        
        return this
    }

    render = () => {
        return this.get()
    }

    getNextPoint = (timestamp: number): SeriePoint | undefined => {
        const index = this.srcData.findIndex(([ts]) => ts === timestamp);
        if (index === -1 || index === this.srcData.length - 1) {
            // Timestamp not found or last point in array
            return undefined;
        }
        const nextIndex = this.srcData.findIndex(([ts], i) => i > index && ts !== timestamp);
        if (nextIndex === -1) {
            // No next point with different timestamp found
            return undefined;
        }
        return this.srcData[nextIndex];
    }

    // Use when you do not expect multiple points with the same timestamp
    getPoint = (ts: number): any => {
        let ret = null
        this.srcData.forEach((p) => {
            if (p[0] === ts) {
                ret = p
            }
        })
        return ret
    }

    // Use when you expect to have multiple points with the same timestamp
    getAllPointsForTimestamp = (timestamp: number): SeriePoint[] => {
        let data = this.filtered ? this.filteredData : this.srcData
        return data.filter(([ts, _, __]) => ts === timestamp);
    }

    // Use when you expect to have multiple points with the same timestamp
    getAllPointsForTimestampWithLabelFilter = (timestamp: number, label: string, labelValue: string): SeriePoint[] => {
        let data = this.filtered ? this.filteredData : this.srcData
        return data.filter(([ts, _, labels]) => ts === timestamp && labels[label] === labelValue);
    }

    getAllPointsForLabel = (label: string, labelValue: string): SeriePoint[] => {
        let data = this.filtered ? this.filteredData : this.srcData
        return data.filter(([__, _, labels]) => {
            return labels[label] === labelValue
        });
    }

    getLastPoint = () => {
        return this.srcData[this.srcData.length - 1]
    }

    get = () => {
        let dataPoints: any[] = []
        let datasource = this.getData()

        datasource.forEach((point) => {
            dataPoints.push({
                x: point[0],
                y: point[1],
                labels: point[2]
            })
        })

        let dataset: { [index: string]: any } = {
            label: this.label == "" ? this.name : this.label,
            data: dataPoints,
            backgroundColor: this.backgroundColor,
            borderColor: this.borderColor,
            pointBorderWidth: this.pointBorderWidth,
            pointHoverRadius: this.pointHoverRadius,
            pointHoverBorderWidth: this.pointHoverBorderWidth,
            pointRadius: this.pointRadius,
            borderWidth: this.borderWidth,
            tension: this.tension,
            fill: this.fill,
            pointBorderColor: this.pointBorderColor,
            pointBackgroundColor: this.pointBackgroundColor,
            pointHoverBackgroundColor: this.pointHoverBackgroundColor,
            pointHoverBorderColor: this.pointHoverBorderColor,
            hidden: this.hidden,
            barThickness: this.barThickness,
        }

        return dataset
    }

    // Formats dataset according to metricconfig
    format = (data: SerieData):SerieData => {
        if (this.metricConfig === null) {
            return data
        }
        const mc = this.metricConfig

        if (mc === undefined) {
            return data
        }

        const SUPPORTED_COUNT_UNITS = ["Scores", "Requests", "Layout Shifts"]
        const SUPPORTED_TIME_DISPLAY_UNITS = ["Days", "Milliseconds", "Seconds"]
        const SUPPORTED_BYTES_DISPLAY_UNITS = ["BytesKilo"]

        if (SUPPORTED_TIME_DISPLAY_UNITS.includes(mc.formatting)) {
            if (!this.name.endsWith("_seconds")) {
                console.error(`${this.name} metric do not support transforming to ${mc.formatting}`)
                return []
            }

            if (mc.formatting === "Days") {
                data = Transformer.secondsToDays(data)
            }

            if (mc.formatting === "Milliseconds") {
                data = Transformer.secondsToMilliseconds(data)
            }
        }

        if (SUPPORTED_BYTES_DISPLAY_UNITS.includes(mc.formatting)) {
            if (!this.name.endsWith("_bytes")) {
                console.error(`${this.name} metric do not support transforming to ${mc.formatting}`)
                return []
            }

            if (mc.formatting === "BytesKilo") {
                data = Transformer.bytesToKilobytes(data)
            }
        }

        return data
    }
}

class Seriee {
    name: string
    data: SerieData

    constructor(name: string, data: SerieData) {
        this.name = name
        this.data = data
    }

    alias = (alias:string) => {
        this.name = alias
        return this
    }

    avg = () => {
        let v = 0
        let c = 0
        for (let p of this.data) {
            v += p[1]
            c += 1
        }
        return v/c
    }

    add = (point:any) => {
        this.data.push(point)
    }

    get = () => {
        return {
            name: this.name,
            data: this.data
        }
    }

    getValueByTimestamp = (ts:number) => {
        let value:number|null = null;
        this.data.forEach((point) => {
            if (point[0] == ts) {
                value = point[1]
            }
        })
        return value
    }

    getPoint = (ts: number): any => {
        let ret = null
        this.data.forEach((p) => {
            if (p[0] === ts) {
                ret = p
            }
        })
        return ret
    }

    getNextPoint = (timestamp: number): SeriePoint | undefined => {
        const index = this.data.findIndex(([ts]) => ts === timestamp);
        if (index === -1 || index === this.data.length - 1) {
            // Timestamp not found or last point in array
            return undefined;
        }
        const nextIndex = this.data.findIndex(([ts], i) => i > index && ts !== timestamp);
        if (nextIndex === -1) {
            // No next point with different timestamp found
            return undefined;
        }
        return this.data[nextIndex];
    }

    getLastPoint = () => {
        return this.data[this.data.length - 1]
    }

    removePointsAfter = (after: number | null) => {
        if (after === null) {
            return this
        }

        let newData: SerieData = []
        this.data.forEach((point) => {
            if (point[0] <= after) {
                newData.push(point)
            }
        })

        return new Seriee(this.name, newData)
    }

    sum = () => {
        let s = 0
        for (let p of this.data) {
            s += p[1]
        }
        return s
    }

    withFilters = (filters: any) => {
        let newData: SerieData = []

        this.data.forEach((point) => {
            let include = true
            Object.keys(filters).forEach((label: string) => {
                let labelFilters = filters[label]

                if (labelFilters.length === 0) {
                    return
                }

                // @ts-ignore
                if (!labelFilters.includes(point[2][label])) {
                    include = false
                }
            })

            if (include) {
                newData.push(point)
            }
        })

        return new Seriee(this.name, newData)
    }

    labelSet = (label: string):Array<string> => {
        let labels:Array<string> = []
        for (let p of this.data) {
            labels.push(p[2]["location"])
        }
        // Remove duplicates
        return Array.from(new Set(labels));
    }

    chartJsDataset = (borderColor: string, backgroundColor: string, gradientStroke: string | null) => {
        let dataPoints: any[] = []
        this.data.forEach((point) => {
            dataPoints.push({
                x: point[0],
                y: point[1]
            })
        })

        let dataset: { [index: string]: any } = {
            label: this.name,
            data: dataPoints,
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            pointBorderWidth: 1,
            pointHoverRadius: 1,
            pointHoverBorderWidth: 1,
            pointRadius: 2,
            borderWidth: 2,
            tension: 0.4,
            fill: false
        }

        if (gradientStroke !== null) {
            dataset.borderColor = gradientStroke
            dataset.pointBorderColor = gradientStroke
            dataset.pointBackgroundColor = gradientStroke
            dataset.pointHoverBackgroundColor = gradientStroke
            dataset.pointHoverBorderColor = gradientStroke
            dataset.backgroundColor = gradientStroke
        }

        return dataset
    }

    toDataset = () => {
        return new Dataset(
            this.name,
            this.data
        )
    }
}


export class Timeserie {
    series: Serie[]
    datasets: Dataset[]
    timeConfig: TimeserieTimeconfig = {
        date_from: "",
        date_to: "",
        aggregation: null,
        retention_name: null
    }

    // Keeps mapping of name to index in the series list
    seriesIndex: { [key: string]: number } = {};

    constructor(timeserie: ITimeserie) {
        // series are old data structures used for apex charts
        // it is being phased out
        this.series = timeserie === undefined ? [] : timeserie.series;

        // datasets are new data structure used for chartjs
        // that supports powerful capabilities like filtering
        // aggregation etc
        this.datasets = this.series.map((serie:any) => {
            return new Dataset(serie.name, serie.data)
        })

        this.series.forEach((serie, index) => this.seriesIndex[serie.name] = index)
        if (timeserie) {
            this.timeConfig = timeserie.time_config
        }
    }

    sumSerie = (name: string): number | undefined => {
        let serie = this.getSerie(name)

        if (serie === undefined) {
            return undefined
        }

        let v = 0

        for (let p of serie.data) {
            v += p[1]
        }

        return v
    }

    getLastPointInSerie = (name: string) => {
        let serie = this.getSerie(name)

        if (serie !== undefined) {
            let len = serie.data.length;

            try {
                return serie.data[len - 1]
            } catch { }
        }

        return null;
    }

    getLastValueInSerie = (name: string) => {
        let serie = this.getSerie(name)

        if (serie !== undefined) {
            let len = serie.data.length;

            try {
                return serie.data[len - 1][1]
            } catch { }
        }

        return null;
    }

    getSerie = (name: string): Seriee => {
        const idx = this.seriesIndex[name]

        if (idx === undefined) {
            return new Seriee(name, [])
        }

        let serie = new Seriee(
            this.series[idx].name,
            this.series[idx].data
        )

        return serie
    }

    getDataset = (name: string): Dataset => {
        const idx = this.seriesIndex[name]

        // If dataset is missing, we just return empty dataset
        // with the same name so front displays empty chart.
        if (idx === undefined) {
            return new Dataset(name, [])

        }

        return this.datasets[idx]
    }

    // getDataset = (name: string): Dataset => {
    //     let series = this.series.filter((serie) => { return serie.name === name })
    //     let empty = new Dataset(name, [])

    //     if (series.length === 0) {
    //         return empty
    //     }

    //     if (series.length === 1) {
    //         const idx = this.seriesIndex[name]
    //         if (idx === undefined) {
    //             return empty
    //         }

    //         return this.datasets[idx]
    //     }

    //     if (series.length > 1) {
    //         // If we reach here it means there are multiple series
    //         // with the same name, probably as a result of aggregation
    //         // by labels.
    //         // Since data is already aggregated we can safely join it into
    //         // a single dataset and then filter it using applyFilters.
    //         let newData: SerieData = []
    //         series.forEach((serie:Serie) => {
    //             newData = newData.concat(serie.data)
    //         })
    //         return new Dataset(name, newData)
    //     }
        
    //     return empty
    // }

    // Finds maximum Y value across all datasets
    maxY = (): number | null => {
        let max: number | null
        try {
            max = this.datasets[0].max();
        } catch {
            return null
        }

        this.datasets.forEach((dataset) => {
            let datasetMax = dataset.max()
            // @ts-ignore
            if (datasetMax !== null && datasetMax > max) {
                max = datasetMax
            }
        })
        return max
    }

    getSerieByLocation = (name: string, location: string): Seriee => {
        const idx = this.seriesIndex[name]

        // In case serie doesn't exist in the data obtained from TSDB
        // we anyway return an empty serie with that name.
        // 
        // This way when getSerieByLocation is being called we do not need
        // to worry about running e.g. function on undefined that would
        // result in an error. 
        const emptySerie = new Seriee(name, [])

        if (idx === undefined) {
            return emptySerie
        }

        try {
            let serieName = this.series[idx].name + " - " + LOCATION_METADATA[location].name
            let data: Array<any> = []

            let serie = new Seriee(serieName, data)

            this.series[idx].data.forEach((point: any) => {
                if (point[2].location === location) {
                    serie.add(point)
                }
            })
            
            return serie
        } catch {
            return emptySerie
        }
    }

    getPointsForTimestamp = (name: string, point: number): SeriePoint | undefined => {
        const serie = this.getSerie(name);

        if (serie === undefined) {
            return undefined
        }

        let ret = undefined
        serie.data.forEach((p) => {
            if (p[0] === point) {
                ret = p
            }
        })

        return ret
    }

    getSeriePoint = (name: string, point: number): SeriePoint | undefined => {
        const serie = this.getSerie(name);

        if (serie === undefined) {
            return undefined
        }

        let ret = undefined
        serie.data.forEach((p) => {
            if (p[0] === point) {
                ret = p
            }
        })

        return ret
    }

    getPoint = (name: string, point: number): SeriePoint | undefined => {
        const serie = this.getSerie(name);

        if (serie === undefined) {
            return undefined
        }
 
        return serie.data[point]
    }
}