import {
  UPMON_MAP_VIEW_DATA_RETRIEVED,
  UPMON_RETRIEVED,
  UPMON_ALERTS_RETRIEVED,
  UPMON_SET_STATE
} from "../constants/Upmon";

import {
  ACCOUNT_SWITCHED
} from "../constants/Account"


const initState = {
  monitors: [],
  upmons: {},
  mapViewData: {},
  fetchMap: {
    "timeseries": {}
  },
  uptimeMonitorsState: {},
  controllerInitialized: false
};

const upmon = (state = initState, action) => {
  switch (action.type) {
    case "UPMON_CONTROLLER_INITIALIZED": {
      return {
        ...state,
        controllerInitialized: true
      }
    }
    case "UPMONS_UPDATE_MONITOR_LIST": {
      return {
        ...state,
        monitors: action.monitors
      }
    }
    case "UPMONS_CONTROLLER_UPDATED":
      return {
        ...state,
        upmonsController: action.upmonsController
      }
    case "UPMON_CONTROLLER_UPDATED":
      return {
        ...state,
        [`${action.monitorUuid}_controller`]: action.controller
      }
    case ACCOUNT_SWITCHED:
      return initState;
    case UPMON_ALERTS_RETRIEVED:
      let upmonAlerts = JSON.parse(JSON.stringify(state.upmons));
      upmonAlerts[action.monitorUuid].alert_definitions =
        action.data.alert_definitions;
      return {
        ...state,
        upmons: upmonAlerts,
      };
    case UPMON_RETRIEVED:
      let upmons = JSON.parse(JSON.stringify(state.upmons));
      if (upmons[action.monitorUuid] === undefined) {
        upmons[action.monitorUuid] = {};
      }

      upmons[action.monitorUuid] = action.data;

      return {
        ...state,
        upmons: upmons,
      };
    case UPMON_SET_STATE:
      let uptimeMonitorsState = JSON.parse(JSON.stringify(state.uptimeMonitorsState));
      uptimeMonitorsState[action.monitorUuid] = action.state
      return {
        ...state,
        uptimeMonitorsState: uptimeMonitorsState
      };
    case UPMON_MAP_VIEW_DATA_RETRIEVED:
      let mapViewData = JSON.parse(JSON.stringify(state.mapViewData));

      if (mapViewData[action.monitorUuid] === undefined) {
        mapViewData[action.monitorUuid] = {};
      }

      mapViewData[action.monitorUuid] = action.data;

      return {
        ...state,
        mapViewData: mapViewData,
      };
    default:
      return state;
  }
};

export default upmon;
