import React, { useState } from "react";
import { connect } from "react-redux";
import { Layout, Tag } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import Logo from "./Logo";
import NavShareSandbox from "./NavShareSandbox";
import NavProfile from "./NavProfile";
import { toggleCollapsedNav, onMobileNavToggle } from "redux/actions/Theme";
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
import utils from "utils";
import useAccount from "hooks/useAccount";

const { Header } = Layout;

export const HeaderNav = (props) => {
  const {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
    toggleEnabled,
    showSignIn
  } = props;

  const account = useAccount();
  const [searchActive, setSearchActive] = useState(false);

  const onSearchClose = () => {
    setSearchActive(false);
  };

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = utils.getColorContrast(headerNavColor);
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  let appHeaderClass = "app-header"
  if (account && account.free_trial && account.starter_key === null) {
    appHeaderClass += "-with-ftbar"
  } 

  return (
    <>
    <Header
      className={`${appHeaderClass} ${mode}`}
      style={{ backgroundColor: headerNavColor }}
    >
      <div className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}>
        <Logo logoType={mode} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <div className="mr-2">
                {isNavTop && !isMobile ? null : toggleEnabled == false ? null : (
                  navCollapsed || isMobile ? (
                    <MenuUnfoldOutlined className="nav-icon" style={{ fontSize: "18px" }} onClick={() => onToggle()} />
                  ) : (
                      <MenuFoldOutlined className="nav-icon" style={{ fontSize: "18px" }} onClick={() => onToggle()} />
                  )
                )}
            </div>

            <div>
              {account != null ? (
                <Tag color="blue">{account.name}</Tag>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="nav-right">
            {
                showSignIn ? (<a href="/auth/login">Sign In</a>) : <></>
            }
            <NavShareSandbox />
            <NavProfile />
          </div>
        </div>
      </div>
    </Header>
    </>
  );
};

const mapStateToProps = ({ theme, account }) => {
  const { navCollapsed, navType, headerNavColor, mobileNav } = theme;
  return { navCollapsed, navType, headerNavColor, mobileNav, account };
};

export default connect(mapStateToProps, {
  toggleCollapsedNav,
  onMobileNavToggle,
})(HeaderNav);
