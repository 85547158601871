import {
    BROWSER_MONITORS_RETRIEVED,
    BROWSER_MONITOR_TIMESERIES_RETRIEVED,
    BROWSER_MONITOR_EXECUTIONS_RETRIEVED,
    BROWSER_MONITOR_SET_STATE,
    BROWSER_MONITOR_RETRIEVED
} from "../constants/Browmon";
import { Timeserie } from "utils/Timeserie";

const initState = {
    browmons: null,
    browmonsTimeseries: {},
    browmonsExecutions: {},
    browmonsState: {}
};

const initCheck = (dict, monitorUuid) => {
    if (dict[monitorUuid] === undefined) {
        dict.monitorUuid = {};
    }
}

const browmon = (state = initState, action) => {
    switch (action.type) {
        case "BROWMON_CONTROLLER_UPDATED":
            return {
                ...state,
                [`${action.monitorUuid}_controller`]: action.controller
            }
        case BROWSER_MONITORS_RETRIEVED:
            return {
                ...state,
                browmons: action.browserMonitors,
            };
        case BROWSER_MONITOR_RETRIEVED:
            let cloneBrowmons = [...state.browmons]
            cloneBrowmons.filter((browmon, index) => {
                if (browmon.uuid == action.browserMonitor.uuid) {
                    cloneBrowmons[index] = action.browserMonitor
                }
            })
            return {
                ...state,
                browmons: cloneBrowmons,
            };
        case BROWSER_MONITOR_TIMESERIES_RETRIEVED:
            // state deep clone
            let browmonsTimeseries = Object.assign({}, state.browmonsTimeseries);
            browmonsTimeseries[action.monitorUuid] = new Timeserie(action.data)
            return {
                ...state,
                browmonsTimeseries: browmonsTimeseries
            }
        case BROWSER_MONITOR_EXECUTIONS_RETRIEVED:
            let browmonsExecutions = JSON.parse(JSON.stringify(state.browmonsExecutions));
            initCheck(browmonsExecutions, action.monitorUuid);
            browmonsExecutions[action.monitorUuid] = action.data.executions
            return {
                ...state,
                browmonsExecutions: browmonsExecutions
            }
        case BROWSER_MONITOR_SET_STATE:
            let browmonsState = Object.assign({}, state.browmonsState);
            browmonsState[action.monitorUuid] = action.state
            return {
                ...state,
                browmonsState: browmonsState
            }
        default:
            return state;
    }
};

export default browmon;
