import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { signOut } from "redux/actions/Auth";
import AuthService from "services/AuthService";
import useAuthStatus from "hooks/useAuthStatus";

const Logout = (props) => {
  let dispatch = useDispatch();
  const authed = useAuthStatus();

  useEffect(() => {
    if (authed) {
      AuthService.logout().then(() => {
        dispatch({ type: "SIGNOUT_SUCCESS" })
        props.signOut();
      })
    }
  })


  return <></>
};

const mapDispatchToProps = {
  signOut,
};

export default connect(null, mapDispatchToProps)(Logout);
