import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, withRouter, useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import AppLayout from "layouts/app-layout";
import DocsLayout from "layouts/docs-layout";
import AuthLayout from "layouts/auth-layout";
import ErrorLayout from "layouts/error-layout";
import InitLayout from "layouts/init-layout";
import LegalLayout from "layouts/legal-layout";
import LighthouseReportLayout from "layouts/lh-report-layout";
import Starter from "./starter";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import Logout from "./auth-views/logout";
import Loading from "components/shared-components/Loading";
import AuthController from "controllers/AuthController";
import useUserInfo from "hooks/useUserInfo";
import UserService from "services/UserService";
import useAuthStatus from "hooks/useAuthStatus";
import AccountService from "services/AccountService";
import { INITIAL_SCREEN } from "configs/AppConfig";
import { getAndSetToken } from "auth/TSDBInterceptor";

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  const renderFn = ({ location }) => {
    if (isAuthenticated) {
      return children;
    } else {
      // Makes sure we do not append the "?next=originally_requested_endpoint" when the previous
      // request was to /auth/logout
      const state = location.pathname === "/auth/logout" ? null : { from: location }
      return (
        <Redirect
          to={{
            pathname: "/auth/login",
            state,
          }}
        />
      );
    }
  };

  return <Route {...rest} render={renderFn} />;
}

function TokenSetter({ children }) {
  let [tsdbToken, setTsdbToken] = useState(false);
  
  useEffect(() => {
    if (!tsdbToken) {
      getAndSetToken().then(() => {
        setTsdbToken(true)
      })
    }
  })

  if (!tsdbToken) {
    return <Loading cover="content" />;
  }

  return children
}

// This components bootstraps the application
// We assume here that the user is already logged in
// and has proper action tokens.
function AccountBootstrap({ children }) {
  let dispatch = useDispatch();
  let userInfo = useUserInfo();
  let history = useHistory();
  let isAuthed = useAuthStatus();

  if (!isAuthed) {
    history.push("/auth/login")
    return
  }

  // If user data not fetched we must first fetch it
  if (userInfo === null) {
    UserService.get().then((userInfo) => {
      return dispatch({ type: "USER_INFO_OBTAINED", userInfo});
    });
    
    return <Loading cover="content" />;
  }

  // Action to perform when something fails during
  // Auto-choosing account
  const onAccountAutoChooseFail = () => {
    console.error("Auto-choosing account has failed")
    AuthController.resetAuthRedirect()
    history.push("/init/choose-account");
  }

  // If the ?accountId=accoutnId has been provided
  // in the initial request let's choose the account
  // for the user automatically. Needed for monitor
  // links e.g. from slack
  const location = AuthController.getAuthRedirect()
  if (location) {
    let params = new URLSearchParams(location.search)

    // Checking if the original URL contained accountId
    // If yes we take user straight to that account
    let accountId = params.get("accountId")
    if (accountId !== null) {
      AccountService.get(accountId).then((account) => {
        try {
          dispatch({ type: "ACCOUNT_RETRIEVED", account });
          params.delete("accountId")
          location["search"] = params.toString();
          AuthController.setAuthRedirect(location)
          history.push(INITIAL_SCREEN);
        } catch {
          onAccountAutoChooseFail()
        }
      }).catch(() => onAccountAutoChooseFail());

      return <Loading cover="content" />;
    }    
  }

  return children
}

export const Views = (props) => {
  const { locale, location } = props;
  const currentAppLocale = AppLocale[locale];
  let history = useHistory();
  let isAuthed = useAuthStatus()

  // isAuthed equals to null means we don't know if user is
  // authenticated or not and we need to send a request to API
  // to verify if auth cookies are present and valid.
  // If valid isAuthed is switched to True
  // if invalid isAuthed is switched to False and user will be
  // redirected to /auth/login
  if (isAuthed === null && !history.location.pathname.startsWith("/auth") && history.location.pathname != "/starter") {
    // We pass location object to isUserLoggedIn because in case of
    // user being not logged in we will preserve original URL he requests
    // in Redux and redirect the user there after he signs in.
    new AuthController().isUserLoggedIn(location)
    return <Loading cover="page" />
  }

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd}>
        <Switch>
          <Route path="/starter" component={Starter} />
          <Route path="/legal">
            <LegalLayout />
          </Route>
          <Route path="/error">
            <ErrorLayout />
          </Route>
          <Route path="/viewer">
            <LighthouseReportLayout />
          </Route>

          <RouteInterceptor path="/auth/logout" isAuthenticated={isAuthed}>
            <Logout />
          </RouteInterceptor>
          <Route path="/auth">
            <AuthLayout />
          </Route>

          <Route path="/documentation">
            <DocsLayout isAuthed={isAuthed} />
          </Route>

          <AccountBootstrap isAuthenticated={isAuthed}>
            <RouteInterceptor path="/init" isAuthenticated={isAuthed}>
              <InitLayout />
            </RouteInterceptor>
            <Route exact path="/">
              <Redirect to="/app" />
            </Route>
            <RouteInterceptor path="/app" isAuthenticated={isAuthed}>
              <TokenSetter>
                <AppLayout location={location} />
              </TokenSetter>
            </RouteInterceptor>
          </AccountBootstrap>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme }) => {
  const { locale } = theme;
  return { locale };
};

export default withRouter(connect(mapStateToProps)(Views));
