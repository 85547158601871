import { LOCATIONS_HUMAN_READABLE } from "constants/LocationConstant";
import { env } from "configs/EnvironmentConfig";
import { notification, message } from "antd";
import store from "redux/store";


class Utils {
  /**
   * Get first character from first & last sentences of a username
   * @param {String} name - Username
   * @return {String} 2 characters string
   */
  static getNameInitial(name) {
    let initials = name.match(/\b\w/g) || [];
    return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
  }

  /**
   * Get current path related object from Navigation Tree
   * @param {Array} navTree - Navigation Tree from directory 'configs/NavigationConfig'
   * @param {String} path - Location path you looking for e.g '/app/dashboards/analytic'
   * @return {Object} object that contained the path string
   */
  static getRouteInfo(navTree, path) {
    if (navTree.path === path) {
      return navTree;
    }
    let route;
    for (let p in navTree) {
      if (navTree.hasOwnProperty(p) && typeof navTree[p] === "object") {
        route = this.getRouteInfo(navTree[p], path);
        if (route) {
          return route;
        }
      }
    }
    return route;
  }

  /**
   * Generates a random value in range of values specified my max and min args.
   * @param {number} min - minimum value
   * @param {number} max - maximum value
   * @return {number} generated number
   */
  static generateRandom(min = 0, max = 100) {
    // find diff
    let difference = max - min;

    // generate random number
    let rand = Math.random();

    // multiply with difference
    rand = Math.floor(rand * difference);

    // add with min value
    rand = rand + min;

    return rand;
  }

  /**
   * Get accessible color contrast
   * @param {String} hex - Hex color code e.g '#3e82f7'
   * @return {String} 'dark' or 'light'
   */
  static getColorContrast(hex) {
    const threshold = 130;
    const hRed = hexToR(hex);
    const hGreen = hexToG(hex);
    const hBlue = hexToB(hex);
    function hexToR(h) {
      return parseInt(cutHex(h).substring(0, 2), 16);
    }
    function hexToG(h) {
      return parseInt(cutHex(h).substring(2, 4), 16);
    }
    function hexToB(h) {
      return parseInt(cutHex(h).substring(4, 6), 16);
    }
    function cutHex(h) {
      return h.charAt(0) === "#" ? h.substring(1, 7) : h;
    }
    const cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000;
    if (cBrightness > threshold) {
      return "dark";
    } else {
      return "light";
    }
  }

  /**
   * Darken or lighten a hex color
   * @param {String} color - Hex color code e.g '#3e82f7'
   * @param {Number} percent - Percentage -100 to 100, positive for lighten, negative for darken
   * @return {String} Darken or lighten color
   */
  static shadeColor(color, percent) {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);
    R = parseInt((R * (100 + percent)) / 100);
    G = parseInt((G * (100 + percent)) / 100);
    B = parseInt((B * (100 + percent)) / 100);
    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;
    const RR =
      R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
    const GG =
      G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
    const BB =
      B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);
    return `#${RR}${GG}${BB}`;
  }

  /**
   * Returns either a positive or negative
   * @param {Number} number - number value
   * @param {any} positive - value that return when positive
   * @param {any} negative - value that return when negative
   * @return {any} positive or negative value based on param
   */
  static getSignNum(number, positive, negative) {
    if (number > 0) {
      return positive;
    }
    if (number < 0) {
      return negative;
    }
    return null;
  }

  /**
   * Returns either ascending or descending value
   * @param {Object} a - antd Table sorter param a
   * @param {Object} b - antd Table sorter param b
   * @param {String} key - object key for compare
   * @return {any} a value minus b value
   */
  static antdTableSorter(a, b, key) {
    if (typeof a[key] === "number" && typeof b[key] === "number") {
      return a[key] - b[key];
    }

    if (typeof a[key] === "string" && typeof b[key] === "string") {
      a = a[key].toLowerCase();
      b = b[key].toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
    return;
  }

  /**
   * Filter array of object
   * @param {Array} list - array of objects that need to filter
   * @param {String} key - object key target
   * @param {any} value  - value that excluded from filter
   * @return {Array} a value minus b value
   */
  static filterArray(list, key, value) {
    let data = list;
    if (list) {
      data = list.filter((item) => item[key] === value);
    }
    return data;
  }

  /**
   * Remove object from array by value
   * @param {Array} list - array of objects
   * @param {String} key - object key target
   * @param {any} value  - target value
   * @return {Array} Array that removed target object
   */
  static deleteArrayRow(list, key, value) {
    let data = list;
    if (list) {
      data = list.filter((item) => item[key] !== value);
    }
    return data;
  }

  /**
   * Wild card search on all property of the object
   * @param {Number | String} input - any value to search
   * @param {Array} list - array for search
   * @return {Array} array of object contained keyword
   */
  static wildCardSearch(list, input) {
    const searchText = (item) => {
      for (let key in item) {
        if (item[key] == null) {
          continue;
        }
        if (
          item[key]
            .toString()
            .toUpperCase()
            .indexOf(input.toString().toUpperCase()) !== -1
        ) {
          return true;
        }
      }
    };
    list = list.filter((value) => searchText(value));
    return list;
  }

  /**
   * Get Breakpoint
   * @param {Object} screens - Grid.useBreakpoint() from antd
   * @return {Array} array of breakpoint size
   */
  static getBreakPoint(screens) {
    let breakpoints = [];
    for (const key in screens) {
      if (screens.hasOwnProperty(key)) {
        const element = screens[key];
        if (element) {
          breakpoints.push(key);
        }
      }
    }
    return breakpoints;
  }

  /**
   * Constructs link to share a sandbox environment.
   */
  static getSandboxSharingLink() {
    const state = store.getState()
    let { account } = state
    if (account.account && this.isSandbox()) {
      return `${env.FRONTEND_URL}/starter?accountId=${account.account.id}&starterKey=${account.account.starter_key}`
    }
  }

  /**
   * Returns wheter current account is a sandbox account.
   */
  static isSandbox() {
    const state = store.getState()
    let { account } = state
    
    if (!account.account) {
      return false
    }

    return account.account.starter_key !== null
  }

  static isEmpty(obj) {
    return (
      [Object, Array].includes((obj || {}).constructor) &&
      !Object.entries(obj || {}).length
    );
  }

  static filterBy(array, kvdict) {
    let keys = Object.keys(kvdict);
    let expected = keys.length;

    let filtered = array.filter((el) => {
      let found = 0;

      keys.forEach((key) => {
        if (el[key] === kvdict[key]) {
          found += 1;
        }
      });

      return found === expected ? true : false;
    });

    return filtered;
  }

  static everyHumanFriendly(value) {
    switch (value) {
      case "60.0":
        return "1m";
      case "300.0":
        return "5m";
      case "900.0":
        return "15m";
      case "1800.0":
        return "30m";
      default:
        return "";
    }
  }

  /*
   * Filters given serie from list of series
   */
  static filterSerie(series, name) {
    if (series === undefined) {
      return undefined;
    }

    let matches = series.filter((elm) => {
      return elm.name === name ? true : false;
    });

    return matches.length >= 1 ? matches[0] : null;
  }

  /*
   * Filters given tuple ([timestamp, value]) from a serie data
   */
  static getSerieValue(serie, index) {
    let retval = undefined;
    serie.data.forEach((tuple) => {
      if (tuple[0] === index) {
        retval = tuple[1];
      }
    });
    return retval;
  }

  static arrSortByKey(arr, key) {
    return arr.sort(function (a, b) {
      var keyA = new Date(a[key]),
        keyB = new Date(b[key]);
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
  }

  static sasin(arr) {
    return arr.reverse();
  }

  static round2(value) {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }

  static average = (array) =>
    this.round2(array.reduce((a, b) => a + b) / array.length);

  static timeseriesTableView(timeseriesList, sortField="request_time", includeTimestamps=false) {
    let objects = {};
    let objectList = [];

    timeseriesList.forEach((timeserie) => {
      timeserie.data.forEach((point) => {
        if (objects[point[0]] === undefined) {
          objects[point[0]] = {};
        }
        if (includeTimestamps) {
          objects[point[0]][timeserie.name] = [point[0], point[1]];
        } else {
          objects[point[0]][timeserie.name] = point[1];
        }
        
      });
    });

    Object.entries(objects).forEach((check) => {
      objectList.push(check[1]);
    });

    return this.sasin(this.arrSortByKey(objectList, sortField));
  }

  /**
   * Transforms a list of region codes like ["EU-1", "EU-2", "NA-1"]
   * into a dict form {"EU": [1], "NA": [1]}
   * @param {Array} regionsArray - Array of region codes ["EU-1", "EU-2", "NA-1"]....
   * @return {Array} map of regions and locations {"EU": [1,2], "NA": [1]}
   */
  static transformRegionCodes(regionsArray) {
    let regionsMap = {};
    regionsArray.forEach((el) => {
      let split = el.split("-");
      let region = split[0];
      let location = split[1];
      if (regionsMap[region] === undefined) {
        regionsMap[region] = [];
      }
      regionsMap[region].push(location);
    });

    return regionsMap;
  }

  /**
   * Transforms a location_config dict {"EU": [1], "NA": [1]}
   * into a human readlabe form ["EU, Ireland", "NA, Virginia"]
   * @param {Object} locationConfig - Location config from API {"EU": [1], "NA": [1]}
   * @return {Array} a nice readable form ["EU, Ireland", "NA, Virginia"]
   */
  static transformLocationConfig(locationConfig) {
    let locations = [];

    Object.keys(locationConfig).forEach((key) => {
      locationConfig[key].forEach((location) => {
        locations.push(`${key}-${location}`);
      });
    });

    return locations;
  }

  /**
   * Transforms a location_config dict {"EU": [1], "NA": [1]}
   * into a human readlabe form ["EU, Ireland", "NA, Virginia"]
   * @param {Array} locationConfig - Array of locations from API {"EU": [1], "NA": [1]}
   * @return {Object} a nice readable form ["EU, Ireland", "NA, Virginia"]
   */
  static transformLocationConfigHR(locationConfig) {
    let locations = [];

    Object.keys(locationConfig).forEach((key) => {
      locationConfig[key].forEach((location) => {
        if (key !== "TEST") {
          try {
            locations.push(LOCATIONS_HUMAN_READABLE[key][location]);
          } catch {}
          
        }
      });
    });

    return locations;
  }

  static debounce(func, wait, immediate) {
    let timeout;
    return function () {
      let context = this, args = arguments;
      let later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      let callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  static buildTimelineSeries(timeline) {
    let first = 0;
    let timelineSeries = [];

    Object.entries(timeline).forEach((entry) => {
      const [key, description] = entry;

      let title;
      let lines = [];

      if (first === 0) {
        title = 0;
      } else {
        title = parseInt(key);
      }
      first += 1;

      description.forEach((val) => {
        lines.push(val[0]);
      });

      timelineSeries.push({
        title: `${title} ms`,
        value: lines,
      });
    });

    return timelineSeries;
  }

  static buildWaterfallSeries(waterfall) {
    let waterfallSeries = [
      {
        data: [],
      },
    ];

    waterfall.forEach((value) => {
      waterfallSeries[0].data.push({
        x: Object.keys(value)[0],
        y: value[Object.keys(value)[0]],
      });
    });

    return waterfallSeries;
  }

  static daysToDhm(days) {
    let seconds = Number(Math.round(days * 86400));
    return this.secondsToDhm(seconds)
  }

  static secondsToDhm(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);

    var dDisplay = d > 0 ? d + ("d ") : "";
    var hDisplay = h > 0 ? h + ("h ") : "";
    var mDisplay = m > 0 ? m + ("m ") : "";
    return dDisplay + hDisplay + mDisplay;
  }
  
  // We should get rid of this function completly.
  static APIErrorNotification(notificationMessage, error) {
    try {
      if (error.response.status === 400 && !error.response.data.errorMessage && !error.response.data.detail) {
        Object.keys(error.response.data).forEach((field) => {
          message.error(`${field}: ${error.response.data[field]}`)
        })
      }

      if (error.timeout) {
        notification.error({
          message: "Something went wrong",
          description: notificationMessage
        })
        return;
      }

      if (error.response.data.errorMessage) {
        notification.error({
          message: notificationMessage,
          description: error.response.data.errorMessage,
        });
        return;
      }

      if (error.response.data.detail) {
        notification.error({
          message: notificationMessage,
          description: error.response.data.detail,
        });
      }
    } catch {}

    return;
  }

  static toQuery(params, delimiter = '&') {
    const keys = Object.keys(params);
    return keys.reduce((str, key, index) => {
      if (typeof params[key] === 'undefined' || params[key] === null) return;
      let query = `${str}${key}=${params[key]}`;
      if (index < (keys.length - 1)) {
        query += delimiter;
      }
      return query;
    }, '');
  }

  /**
   * A helper that builds a widget object. If widget data is missing
   * it returns empty widget.
   * @param {Array} widgets - Array of widgets from API
   * @param {String} title - Title of the widget
   * @param {String} index - Index of widget data in the widgets array
   * @param {Function} formatter - Formatter function to pass the widget value through
   * @return {Object} a filled widget object, or an empty one.
   */
  static constructWidget(widgets, title, index, formatter = undefined) {
    let widget = { title: title };

    try {
      widget.color = widgets[index]["color"];
      widget.value =
        formatter === undefined
          ? widgets[index]["median"]
          : formatter(widgets[index]["median"]);
      return widget;
    } catch (TypeError) {
      widget.value = "-";
      return widget;
    }
  }

  static isAccountAdmin(userInfo, accountUsers) {
    let userId = userInfo.id
    let isAdmin = false

    accountUsers.forEach((user) => {
      if (userId == user.key) {
        if (user.role === "owner" || user.role === "admin") {
          isAdmin = true
        }
      }
    })
    return isAdmin
  }

  static calculateAggregation(timerange, dpWidthMsec, dpToDisplay) {
    let diff = timerange.to.ms - timerange.from.ms
    let pointsToDisplayOnChart = diff / dpWidthMsec
    if (pointsToDisplayOnChart < dpToDisplay) {
      return null
    }
    return Math.round((pointsToDisplayOnChart / dpToDisplay) * dpWidthMsec)
  }
}

export class Formatters {
  static tz(value) {
    const resolvedOptions = Intl.DateTimeFormat().resolvedOptions()
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
    const date = new Date(value);
    const formattedDate = date.toLocaleString(resolvedOptions.locale, options).replace(",", "")
    return formattedDate
  }

  static seconds(value) {
    return `${value.toFixed(2)}s`;
  }

  /**
   * Format bytes as human-readable text.
   *
   * @param bytes Number of bytes.
   * @param si True to use metric (SI) units, aka powers of 1000. False to use
   *           binary (IEC), aka powers of 1024.
   * @param dp Number of decimal places to display.
   *
   * @return Formatted string.
   */
  static humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
  }

  static bytes(value) {
    return Formatters.humanFileSize(value);
  }

  static integer(value) {
    return parseInt(value);
  }

  static round(value, size) {
    return (
      Math.round((value + Number.EPSILON) * Math.pow(10, size)) /
      Math.pow(10, size)
    );
  }
}

export function millisecondsFormatter(value) {
  if (value === null) {
    return value;
  }
  let v = (value / 1000).toFixed(2);
  return `${v}s`;
}

export function secondsFormatter(value) {
  return `${value.toFixed(2)}s`;
}

export function defaultFormatter(value) {
  return value;
}

export function bytesFormatter(value) {
  let v = Math.round(value / 1000);
  return `${v}kb`;
}

export function dateFormatter(value) {
  return new Date(value).toISOString().slice(0, 19).replace("T", " ");
}

export function tzFormat(value) {
  let dt = new Date(value);
  return dt.toISOString().slice(0, 19).replace("T", " ");
}

export function compareSinglestats(monitorsData) {
  // A following data structure is generated
  // [
  // 	{
  // 		name: "SpeedIndex",
  // 		categories: ["https://wp.pl", "https://onet.pl"],
  // 		series: [
  // 			{
  // 				data: [1, 2]
  // 			}
  // 		]
  // 	},
  // 	{
  // 		name: "FirstRender",
  // 		categories: ["https://wp.pl", "https://onet.pl"],
  // 		series: [
  // 			{
  // 				data: [10, 15]
  // 			}
  // 		]
  // 	}
  // ]

  let categories = [];
  let singleStatsMap = {};

  monitorsData.forEach((monitor) => {
    categories.push(monitor.url);
    Object.keys(monitor.data.singlestats).forEach((stat) => {
      if (Utils.isEmpty(singleStatsMap[stat])) {
        singleStatsMap[stat] = {};
        singleStatsMap[stat].series = [{ data: [], name: stat }];
      }
      singleStatsMap[stat].series[0].data.push(
        monitor.data.singlestats[stat].median
      );
    });
  });

  Object.keys(singleStatsMap).forEach((stat) => {
    singleStatsMap[stat].name = stat;
    singleStatsMap[stat].categories = categories;
  });

  return singleStatsMap;
}

export default Utils;
