import { ROLE_OWNER, ROLE_ADMIN, ROLE_USER, Roles } from "configs/Roles";

export default class AccessUtils {
    static hasAccess(roles: Roles, userInfo: any, account: any): boolean {
        if (userInfo === null || account.account === null) {
            return false
        }

        let accountId = account.account.id
        let userRoles = userInfo.roles

        const isAccountAdmin = () => {
            let nameToLook = `${accountId}_admins`;
            let isAdmin = false;

            userRoles.forEach((role: any) => {
                if (role.name === nameToLook) {
                    isAdmin = true
                }
            });

            return isAdmin
        }

        let isAdmin = isAccountAdmin()

        if (roles.includes(ROLE_ADMIN) && isAdmin) {
            return true
        }

        return false
    }
}