import React from "react";
import { Layout, Grid } from "antd";
import { Switch, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import SideNav from "components/layout-components/SideNav";
import TopNav from "components/layout-components/TopNav";
import MobileNav from "components/layout-components/MobileNav";
import HeaderNav from "components/layout-components/HeaderNav";
import PageHeader from "components/layout-components/PageHeader";
import Footer from "components/layout-components/Footer";
import SandboxTour from "components/layout-components/SandboxTour";
import DocsViews from "views/docs-views";
import navigationConfig from "configs/NavigationConfig";
import FreeTrialBar from "components/layout-components/FreeTrialBar";
import useAccount from "hooks/useAccount";

import {
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
} from "constants/ThemeConstant";
import utils from "utils";

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const DocsLayout = ({ navCollapsed, navType, location, isAuthed }) => {
  const currentRouteInfo = utils.getRouteInfo(
    navigationConfig,
    location.pathname
  );

  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes("lg");
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;

  const getLayoutGutter = () => {
    return 0
  };

  let account = useAccount();
  let appContentClass = "app-content"
  if (account && account.free_trial && account.starter_key === null) {
    appContentClass += "-with-ftbar"
  } 

  return (
    <Layout>
      <FreeTrialBar />
      <SandboxTour />
      <HeaderNav isMobile={isMobile} showSignIn={isAuthed === false} />
      {isNavTop && !isMobile && isAuthed ? <TopNav routeInfo={currentRouteInfo} /> : null}
      <Layout className="app-container">
        {isNavSide && !isMobile && isAuthed ? (
          <SideNav routeInfo={currentRouteInfo} />
        ) : null}
        <Layout
          className="app-layout"
          style={{ paddingLeft: getLayoutGutter() }}
        >
          <div className={`${appContentClass} ${isNavTop ? "layout-top-nav" : ""}`}>
            <PageHeader
              display={currentRouteInfo?.breadcrumb}
              title={currentRouteInfo?.title}
            />
            <Content>
              <Switch>
                <Route path="" component={DocsViews} />
              </Switch>
            </Content>
          </div>
          <Footer />
        </Layout>
      </Layout>
      {isMobile && <MobileNav />}
    </Layout>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } = theme;
  return { navCollapsed, navType, locale};
};

export default withRouter(connect(mapStateToProps)(DocsLayout));
