import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import useAccount from "hooks/useAccount";
import Utils from "utils";

export const FreeTrialBar = (props) => {
    const isMobile = window.innerWidth < 768;
    
    let account = useAccount();

    // Disable bar for mobile, when account is not loaded, when account is not on free trial
    if (isMobile || !account || account.free_trial === null || account.starter_key !== null) {
        return <></>
    }

    const getContent = () => {
        if (account.free_trial.active && account.free_trial.remaining > 0) {
            return `Fee trial time left: ${Utils.secondsToDhm(account.free_trial.remaining)} -`
        }

        return 'Trial has expired -'
    }

    return (
        <div className="free-trial-bar">
            <div className="free-trial-info">
                <span style={{ color: "#455560", fontWeight: "bold", fontSize: 11 }}><InfoCircleOutlined /> {getContent()}
                    <a style={{ cursor: "pointer" }} href="/app/admin/subscriptions"> Purchase a subscription</a>
                </span>
            </div>
        </div>
    );
};

export default FreeTrialBar;
