import { SeriePoint } from "./Timeserie";
import Utils from "utils";

export class Transformer {
    static secondsToDays(seriePoints: SeriePoint[]): SeriePoint[] {
        const transformedPoints: SeriePoint[] = [];

        for (let i = 0; i < seriePoints.length; i++) {
            const timestamp = seriePoints[i][0];
            const seconds = seriePoints[i][1];
            const labels = seriePoints[i][2];
            const days = seconds / 86400; // 86400 seconds in a day
            transformedPoints.push([timestamp, days, labels]);
        }

        return transformedPoints;
    }

    static secondsToMilliseconds(seriePoints: SeriePoint[]): SeriePoint[] {
        const transformedPoints: SeriePoint[] = [];

        for (let i = 0; i < seriePoints.length; i++) {
            const timestamp = seriePoints[i][0];
            const seconds = seriePoints[i][1];
            const labels = seriePoints[i][2];
            const ms = Math.round(seconds * 1000) // 1000 milliseconds in a second
            transformedPoints.push([timestamp, ms, labels]);
        }

        return transformedPoints;
    }

    static bytesToKilobytes(seriePoints: SeriePoint[]): SeriePoint[] {
        const transformedPoints: SeriePoint[] = [];

        for (let i = 0; i < seriePoints.length; i++) {
            const timestamp = seriePoints[i][0];
            const bytes = seriePoints[i][1];
            const labels = seriePoints[i][2];
            const kb = Utils.round2(bytes / 1000) // 1000 bytes in a kilobyte
            transformedPoints.push([timestamp, kb, labels]);
        }

        return transformedPoints;
    }
}