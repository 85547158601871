import fetch from "auth/FetchInterceptor";

const AlertService = {};

AlertService.createAlert = function (data, accountId) {
  /*
   * Creates an alert
   */
  return fetch({
    url: `/account/${accountId}/alerting/alertdefinitions`,
    method: "post",
    data,
  });
};

AlertService.patchAlert = function (accountId, alertUuid, data) {
  /*
   * Updates an alert
   */
  return fetch({
    url: `/account/${accountId}/alerting/alertdefinitions/${alertUuid}/`,
    method: "PATCH",
    data,
  });
};

AlertService.refreshAlerts = function (accountId, monitorUuid) {
  /*
   * Refresh alerts for fiven monitor
   */
  return fetch({
    url: `/account/${accountId}/uptime-monitors/${monitorUuid}/`,
    method: "get",
  });
};


AlertService.list = function (accountId) {
  return fetch({
    url: `/account/${accountId}/alerting/alertdefinitions`,
    method: "GET"
  })
}

AlertService.deleteAlert = function (accountId, alertUuid) {
  return fetch({
    url: `/account/${accountId}/alerting/alertdefinitions/${alertUuid}/`,
    method: "DELETE"
  })
}

export default AlertService;
