import {
  DashboardOutlined,
  MonitorOutlined,
  BookOutlined,
  UserOutlined,
  SettingOutlined,
  MoneyCollectOutlined,
  BugOutlined,
  ChromeOutlined,
  FileUnknownOutlined
} from "@ant-design/icons";
import { ROLE_ADMIN, ROLE_OWNER } from "./Roles";

const dashBoardNavTree = [
  {
    key: "general",
    path: "",
    title: "sidenav.general",
    breadcrumb: false,
    submenu: [
      // {
      //   key: "dashboard",
      //   path: "/app/apps/dashboard",
      //   title: "Dashboard",
      //   icon: HomeOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
      {
        key: "uptimemonitor",
        path: "/app/apps/uptime_monitors",
        title: "Uptime Monitors",
        icon: MonitorOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "performancemonitor",
        path: "/app/apps/performance_monitors",
        title: "Performance Monitors",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "browsermonitor",
        path: "/app/apps/browser_monitors",
        title: "Browser Monitors",
        icon: ChromeOutlined,
        breadcrumb: false,
        submenu: [],
      },
      // {
      //   key: "rum",
      //   path: "/app/apps/rum",
      //   title: "Real User Monitoring",
      //   icon: SwapOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
      {
        key: "integrations",
        path: "/app/apps/integrations",
        title: "Integrations",
        icon: SettingOutlined,
        breadcrumb: false,
        submenu: [],
      }
    ],
  },
];

const managementNavTree = [
  {
    key: "management",
    path: "",
    title: "sidenav.account-management",
    icon: BookOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "management-users",
        path: "/app/pages/user-list",
        title: "sidenav.management.users",
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "management-subscriptions",
        path: "/app/admin/subscriptions",
        title: "sidenav.management.subscriptions",
        icon: MoneyCollectOutlined,
        breadcrumb: false,
        submenu: [],
        roleAccess: [ROLE_ADMIN, ROLE_OWNER]
      },
    ],
  },
];

const otherNavTree = [
  {
    key: "others",
    path: "",
    title: "sidenav.others",
    icon: BookOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "feedback-bug-report",
        path: "/app/pages/feedback",
        title: "sidenav.management.feedbackbugreport",
        icon: BugOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'docs-documentation',
    path: '/documentation',
    title: 'sidenav.docs.documentation',
    icon: FileUnknownOutlined,
    breadcrumb: false,
    submenu: []
  }
];

const navigationConfig = [
  ...dashBoardNavTree,
  ...managementNavTree,
  ...otherNavTree
];

export default navigationConfig;
