import React, { useState } from "react";
import Loading from "components/shared-components/Loading";
import AccountService from "services/AccountService";
import { Redirect } from 'react-router-dom';
import { notification } from "antd";

const ProcessInvite = (props) => {
    let [processed, setProcessed] = useState(false)
    const params = new URLSearchParams(props.location.search);

    let inviteKey = params.get("inviteKey")

    if (inviteKey === null) {
        return <Redirect to="/" />
    }

    if (!processed) {
        AccountService.acceptInvite({ key: inviteKey }).then((response) => {
            notification.success({
                message: "Invitation accepted!",
                description: <>Inivitation has been accepted! Please log in using the <strong>{response.email}</strong> email address to access the account. If you don't have an account yet, and you don't login using Google SSO, please register first.</>,
                placement: "topRight",
                duration: 20
            });
            setProcessed(true);
        }).catch((error) => {
            if (error?.response?.data?.errorCode === "INVITATION_ALREADY_ACCEPTED") {
                notification.error({
                    message: "Already accepted!",
                    description: "This invitation has already been accepted",
                    placement: "topRight",
                    duration: 20
                });
            } else {
                notification.error({
                    message: "Something went wrong!",
                    description: `Accepting invite failed. Please retry or contact support at contact@dtekt.io`,
                    placement: "topRight",
                    duration: 20
                });

            }

            setProcessed(true);
        })
    }

    if (!processed) {
        return <Loading cover="content" />;
    } else {
        return <Redirect to="/auth/login" />
    }
}

export default ProcessInvite;
