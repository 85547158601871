// import axios from "auth/FetchInterceptor";
import axios from "auth/TSDBInterceptor";
import { Timerange } from "utils/Time";
import AccountService from "services/AccountService";
import { env } from "configs/EnvironmentConfig";

let TSDBService: {
    range: (any),
    mrange: (any),
    fetchMetadata: (any),
    query: (any),
    queryIndex: (any),
    metricInfo: (any),
    tokenizeQuery: (any),
    fetchMetricsConfig: (any),
    queryAll: (any)
}

TSDBService = {
    fetchMetadata: (accountId: string, monitorUuid: string, hashKey: number, hashName: string) => {
        let url = `${env.TSDB_ADDRESS}/metadata`

        var qParams = new URLSearchParams();

        qParams.append("hashName", hashName)
        qParams.append("hashKey", hashKey.toString());
        qParams.append("accountId", accountId);
        qParams.append("monitorUuid", monitorUuid);

        return axios.get(url, {
            params: qParams
        })
    },
    query: (accountId: string, query: string, timerange: Timerange, retention: string) => {
        let url = `${env.TSDB_ADDRESS}/query`

        var qParams = new URLSearchParams();
        qParams.append("startTs", timerange.from.ms.toString());
        qParams.append("endTs", timerange.to.ms.toString());
        qParams.append("accountId", accountId);
        qParams.append("q", query);
        qParams.append("retention", retention)

        return axios.get(url, {
            params: qParams
        })
    },
    queryAll: (accountId: string, queries: string[], timerange: Timerange, retention: string) => {
        let url = `${env.TSDB_ADDRESS}/query`

        var qParams = new URLSearchParams();
        qParams.append("startTs", timerange.from.ms.toString());
        qParams.append("endTs", timerange.to.ms.toString());
        qParams.append("accountId", accountId);
        queries.forEach((q:string) => {
            qParams.append("q", q);
        })
        qParams.append("retention", retention)

        return axios.get(url, {
            params: qParams
        })
    },
    range: (accountId: string, monitorUuid: string, timerange: Timerange, params: { [index: string]: Array<string> }) => {
        let url = `${env.TSDB_ADDRESS}/range`

        var qParams = new URLSearchParams();
        qParams.append("startTs", timerange.from.ms.toString());
        qParams.append("endTs", timerange.to.ms.toString());

        Object.keys(params).forEach((name) => {
            params[name].forEach((v) => {
                qParams.append(name, v)
            })
        })

        qParams.append("accountId", accountId);
        qParams.append("monitorUuid", monitorUuid);
        return axios.get(url, {
            params: qParams
        })
    },
    mrange: (accountId: string, monitorUuid: string, timerange: Timerange, params: { [index: string]: Array<string> }) => {
        let url = `${env.TSDB_ADDRESS}/mrange`

        var qParams = new URLSearchParams();
        qParams.append("startTs", timerange.from.ms.toString());
        qParams.append("endTs", timerange.to.ms.toString());

        Object.keys(params).forEach((name) => {
            params[name].forEach((v) => {
                qParams.append(name, v)
            })
        })

        qParams.append("accountId", accountId);
        qParams.append("monitorUuid", monitorUuid);

        return axios.get(url, {
            params: qParams
        })
    },
    queryIndex: (accountId: string) => {
        let url = `${env.TSDB_ADDRESS}/queryIndex`

        var qParams = new URLSearchParams();

        qParams.append("accountId", accountId);

        return axios.get(url, {
            params: qParams
        })
    },
    metricInfo: (accountId: string, metric: string) => {
        let url = `${env.TSDB_ADDRESS}/metricInfo`

        var qParams = new URLSearchParams();

        qParams.append("accountId", accountId);
        qParams.append("metric", metric);

        return axios.get(url, {
            params: qParams
        })
    },
    tokenizeQuery: (accountId: string, query: string) => {
        let url = `${env.TSDB_ADDRESS}/tokenize`

        var qParams = new URLSearchParams();

        qParams.append("accountId", accountId);
        qParams.append("q", query);

        return axios.get(url, {
            params: qParams
        })
    },
    fetchMetricsConfig: (accountId: string) => {
        let url = `${env.TSDB_ADDRESS}/metricsConfig`
        var qParams = new URLSearchParams();
        qParams.append("accountId", accountId);
        return axios.get(url, {
            params: qParams
        })
    }
}

export default TSDBService;
