import styled from "styled-components";

export const Title = styled.h4`
            color: "#455560";
        `
export const Highlight = styled.span`
            color: "#455560";
            `
export const Bold = styled.span`
            font-weight: bold;
        `
export const Bold15 = styled.span`
            font-weight: 500;
            font-size: 15px
        `
export const Bold22 = styled.span`
            font-weight: 500;
            font-size: 22px
        `
export const SpanValue = styled.span`
            font-size: 14px;
            letter-spacing: 2px;
        `
export const SpanUnit = styled.span`
            font-size: 10px;
        `
export const SpanBold = styled.span`
            font-weight: 500;
        `
export const SpanPerformanceScore = styled.span`
            margin-left: 24px;
            font-size: 22px;
            font-weight: 500;
        `
export const Timestamp = styled.span`
            font-size: 12px;
            font-family: monospace;
            letter-spacing: 1.2px;
        `
export const TooltipLegendMarker = styled.span<{ color: string }>`
    background: ${p => (p.color)};
    color: ${p => (p.color)};
    height: 14px;
    width: 14px;
    left: 0px;
    top: 2px;
    border-width: 0px;
    border-color: rgb(255, 255, 255);
    border-radius: 14px;
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-right: 3px;
    border-style: solid;
`; 