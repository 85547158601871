import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Loading from "components/shared-components/Loading";

export const LegalViews = (props) => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route
          path={`${props.match.url}/privacy`}
          component={lazy(() => import(`./privacy`))}
        />
        <Route
          path={`${props.match.url}/terms`}
          component={lazy(() => import(`./terms`))}
        />
      </Switch>
    </Suspense>
  );
};

export default LegalViews;
