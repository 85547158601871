
export interface ILocationMultiselect {
    title: string
    value: string
    key: string
}

export interface IRegionMultiselect {
    title: string
    value: string
    key: string
    children: Array<ILocationMultiselect>
}

export interface ILocationMetadata {
    color: string,
    name: string,
    geoname: string
}

// Geoname is for the marking the region on the map
// And name is a user friendly name we display in dashboard
export const LOCATION_METADATA: { [index: string]: ILocationMetadata } = {
    "EU-1": { color: "#04d182", name: "Ireland", geoname: "Ireland" },
    "EU-2": { color: "#0080FF", name: "Poland", geoname: "Poland" },
    "NA-1": { color: "#3e82f7", name: "US, Virginia", geoname: "Virginia" },
    "NA-2": { color: "#8e3ef7", name: "US, California", geoname: "California" },
    "SA-1": { color: "#a1f73e", name: "Brazil", geoname: "Brazil" },
    "AS-1": { color: "#b01717", name: "China", geoname: "China" }
}

export const MultiselectRegionConfigs = new Map<string, IRegionMultiselect>([
    [
        "EU",
        {
            title: "Europe",
            value: "EU",
            key: "EU",
            children: [],
        },
    ],
    [
        "NA",
        {
            title: "North America",
            value: "North America",
            key: "NA",
            children: [],
        },
    ],
    [
        "SA",
        {
            title: "South America",
            value: "South America",
            key: "SA",
            children: [],
        },
    ],
    [
        "TEST",
        {
            title: "Test",
            value: "TEST",
            key: "TEST",
            children: [],
        }
    ]
])


export const MultiselectLocationConfigs = new Map<string, ILocationMultiselect>([
    [
        "EU-1",
        {
            title: "Ireland",
            value: "EU-1",
            key: "EU-1",
        }
    ],
    [
        "EU-2",
        {
            title: "Poland",
            value: "EU-2",
            key: "EU-2",
        }
    ],
    [
        "SA-1",
        {
            title: "Brazil",
            value: "SA-1",
            key: "SA-1",
        }
    ],
    [
        "NA-1",
        {
            title: "US, Virginia",
            value: "NA-1",
            key: "NA-1",
        }
    ],
    [
        "NA-2",
        {
            title: "US, California",
            value: "NA-2",
            key: "NA-2",
        }
    ]
])

export default class LocationUtils {
    static listFromConfig(locationConfig: any): Array<string> {
        let locationList:Array<string> = []
        Object.keys(locationConfig).forEach((region) => {
            let reg = locationConfig[region]
            reg.forEach((loc:number) => {
                locationList.push(`${region}-${loc}`)
            })
        })

        return locationList
    }

    static multiselectFromConfig(locationConfig: any): any {
        let multiselect:Array<IRegionMultiselect> = []

        Object.keys(locationConfig).forEach((regcode:string) => {
            let region = JSON.parse(JSON.stringify(MultiselectRegionConfigs.get(regcode)))

            locationConfig[regcode].forEach((loccode:string) => {
                let location = JSON.parse(JSON.stringify(MultiselectLocationConfigs.get(`${regcode}-${loccode}`)))
                if (location !== undefined && region !== undefined) {
                    region.children.push(location)
                }
            })

            if (region !== undefined) {
                multiselect.push(region)
            }
        })

        return multiselect
    }

    // This function changes ["EU-1", "NA-1", "EU-2"] into {"EU": [1, 2], "NA": [1]}
    static listToConfig(locationList: any) {
        let config:{[index: string]: Array<number>} = {}
        locationList.forEach((location:any) => {
            let [regcode, loccode] = location.split("-")

            if (!(regcode in config)) {
                config[regcode] = []
            }
            if (!config[regcode].includes(loccode)) {
                config[regcode].push(loccode)
            }
        })
        return config
    }

    static multiselectFromList(locationList: any): any {
        return LocationUtils.multiselectFromConfig(
            LocationUtils.listToConfig(locationList)
        )
    }

    static generateColorsForLocations(locations:Array<string>): Array<string> {
        let colors:Array<string> = []
        locations.forEach((location:string) => {
            try {
                colors.push(LOCATION_METADATA[location].color)
            } catch {
                console.log(`Unknown location ${location}`)
            }
        })
        return colors
    }
}