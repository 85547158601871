import fetch from "auth/FetchInterceptor";

const AuthService = {};

AuthService.loginGoogle = function (data) {
  return fetch({
    url: "/rest-auth/google",
    method: "post",
    data: data,
  });
};

AuthService.logout = function () {
  return fetch({
    url: "/rest-auth/logout/",
    method: "post"
  });
};

AuthService.verifyEmail = function (key) {
  return fetch({
    url: "/rest-auth/registration/verify-email/",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: { key: key },
  });
};

AuthService.resetCookies = function() {
  return fetch({
    url: "/user/reset-cookies"
  })
}

export default AuthService;
