import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Loading from "components/shared-components/Loading";

export const DocsViews = (props:any) => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          component={lazy(() => import(`./docs`))}
          path={`${props.match.url}`}
        />
      </Switch>
    </Suspense>
  );
};

export default DocsViews;
