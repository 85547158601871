export const ACCOUNT_CREATED = "ACCOUNT_CREATED";
export const ACCOUNT_USERS_RETRIEVED = "ACCOUNT_USERS_RETRIEVED";
export const ACCOUNT_RETRIEVED = "ACCOUNT_RETRIEVED";
export const ACCOUNT_SWITCHED = "ACCOUNT_SWITCHED";
export const ACCOUNT_PERFORMANCE_MONITORS_RETRIEVED =
  "ACCOUNT_PERFORMANCE_MONITORS_RETRIEVED";
export const ACCOUNT_UPTIME_MONITORS_RETRIEVED =
  "ACCOUNT_UPTIME_MONITORS_RETRIEVED";
export const ACCOUNT_ID = "account_id";
export const ACCOUNT_UPTIME_MONITOR_TIMESERIES_RETRIEVED =
  "ACCOUNT_UPTIME_MONITOR_TIMESERIES_RETRIEVED";
export const ACCOUNT_UPTIME_MONITOR_CHECK_DETAILS_RETRIEVED =
  "ACCOUNT_UPTIME_MONITOR_CHECK_DETAILS_RETRIEVED";
export const ACCOUNT_UPTIME_MONITOR_LATEST_CHECKS_RETRIEVED =
  "ACCOUNT_UPTIME_MONITOR_LATEST_CHECKS_RETRIEVED";
export const ACCOUNT_PERFORMANCE_MONITOR_TIMESERIES_RETRIEVED =
  "ACCOUNT_PERFORMANCE_MONITOR_TIMESERIES_RETRIEVED";
export const ACCOUNT_PERFORMANCE_MONITOR_DOMAINS_RETRIEVED =
  "ACCOUNT_PERFORMANCE_MONITOR_DOMAINS_RETRIEVED";
export const ACCOUNT_UPTIME_MONITOR_CHECK_LIST_RETRIEVED =
  "ACCOUNT_UPTIME_MONITOR_CHECK_LIST_RETRIEVED";
export const ACCOUNT_BILLING_DETAILS_RETRIEVED =
  "ACCOUNT_BILLING_DETAILS_RETRIEVED";
export const ACCOUNT_INTEGRATIONS_RETRIEVED = "ACCOUNT_INTEGRATIONS_RETRIEVED";