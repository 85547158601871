import React from "react";
import { Layout, Grid, BackTop } from "antd";
import { Switch, Route } from "react-router-dom";
import LegalViews from "views/legal-views";
import HeaderNav from "components/layout-components/HeaderNav";
import Footer from "components/layout-components/Footer";
import utils from "utils";

const { useBreakpoint } = Grid;

export const LegalLayout = () => {
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes("lg");

  return (
    <Layout>
      <HeaderNav isMobile={isMobile} toggleEnabled={false} />
      <div className="h-100">
        <div className="container d-flex flex-column h-100">
          <div className="app-content">
            <Switch>
              <Route path="" component={LegalViews} />
            </Switch>
          </div>
        </div>
      </div>
      <BackTop />
      <Footer />
    </Layout>
  );
};

export default LegalLayout;
