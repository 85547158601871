import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import { notification } from "antd";
import AuthController from "controllers/AuthController";

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

const REFRESH_TOKEN_URL = `${API_BASE_URL}/rest-auth/token/refresh/`;

// Config
const PUBLIC_REQUEST_KEY = "public-request";

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    if (!config.headers[PUBLIC_REQUEST_KEY]) {
      // Make sure that DTEKT_RT and DTEKT_AT cookies
      // are included with the backend request
      config.withCredentials = true
    }
    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: "Error",
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return new Promise((resolve, reject) => {
      if (error === undefined) {
        reject(error);
        return
      }

      if (error.code === "ECONNABORTED") {
        reject({ timeout: true });
        return;
      }

      if (!error.response) {
        reject(error);
        return;
      }

      // Remove token and redirect
      if (error.response.status >= 400 && error.response.status !== 401) {
        reject(error);
        return;
      }

      // Attempt to refresh the JWT token
      if (
        error.response.status === 401 &&
        error.config &&
        !error.config._retry
      ) {
        return fetch(REFRESH_TOKEN_URL, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          redirect: "follow",
          referrer: "no-referrer",
        }).then(() => {
          const originalReq = error.config;
          originalReq._retry = true;
          return axios(originalReq)
            .then((response) => {
              resolve(response.data);
            })
            .catch(function () {
              // The original request, even after token has been refreshed
              // has failed. We completely reset the app by calling the reset()
              // that cleans up the DTEKT_AT and DTEKT_RT cookies
              // localStorage and redux store.
              AuthController.reset()
            });
          }).catch(() => {
            // Refresh token failed. We completely reset the app by calling the reset()
            // that cleans up the DTEKT_AT and DTEKT_RT cookies
            // localStorage and redux store.
            AuthController.reset()
          })
       }
    });
  }
);

export default service;