import React from "react";
import { Redirect } from "react-router-dom";
import { LS_ACCOUNT_ID } from "constants/AccountConstant";
import { accountRetrieved } from "redux/actions/Account";
import Loading from "components/shared-components/Loading";
import AccountService from "services/AccountService";
import { connect } from "react-redux";


const withAccountChosen = (WrappedComponent) => {
  const hoc = (props) => {
    let accountId = localStorage.getItem(LS_ACCOUNT_ID);

    if (accountId === null) {
      return <Redirect to="/init/choose-account" />;
    }

    if (props.account === null) {
      AccountService.get(accountId).then((acc) => {
        props.accountRetrieved(acc);
      });
      return <Loading cover="content" />;
    }

    return <WrappedComponent {...props} />;
  };
  return connect(mapStateToProps, mapDispatchToProps)(hoc);
};

const mapDispatchToProps = {
  accountRetrieved,
};

const mapStateToProps = (state) => {
  return {
    account: state.account.account,
  };
};

export default withAccountChosen;
