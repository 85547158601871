import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import LighthouseReportViewer from "./lighthouse-report-viewer";
import withUserInfo from "hoc/withUserInfo";
import withMinimumOneAccount from "hoc/withMinimumOneAccount";
import withAccountChosen from "hoc/withAccountChosen";

const LighthouseReportViewerViews = ({ match }) => {
    return (
        <Suspense fallback={<Loading cover="page" />}>
            <Switch>
                <Route
                    path={`${match.url}/:monitorUuid/report/:timestamp`}
                    component={LighthouseReportViewer}
                />
            </Switch>
        </Suspense>
    );
};

export default withUserInfo(withMinimumOneAccount(withAccountChosen(LighthouseReportViewerViews)));