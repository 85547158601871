import { ALERTS_RETRIEVED, ALERT_HANDLERS_RETRIEVED } from "../constants/Alert";

import { ACCOUNT_SWITCHED } from "../constants/Account"

const initState = {
  alerthandlers: {},
  alerts: []
};

const alert = (state = initState, action) => {
  switch (action.type) {
    case ACCOUNT_SWITCHED:
      return initState
    case ALERT_HANDLERS_RETRIEVED:
      let handlers = JSON.parse(JSON.stringify(state.alerthandlers));
      handlers[action.accountId] = action.data;
      return {
        ...state,
        alerthandlers: handlers,
      };
    case ALERTS_RETRIEVED:
      return {
        ...state,
        alerts: action.data
      }
    default:
      return state;
  }
};

export default alert;
