import { call, put, takeEvery } from "redux-saga/effects";

import {
    ACCOUNT_RETRIEVED
} from "redux/constants/Account"

import {
    ALERTS_RETRIEVED
} from "redux/constants/Alert"

import AlertService from "services/AlertService";

/**
 * Side-effect function when account is retrieved. It lists statuses of
 * all alerts in the account
 * @param action
 */
export function* listAlerts(action: any): Generator {
    let alerts: any = yield call(AlertService.list, action.account.id)

    yield put({
        type: ALERTS_RETRIEVED,
        data: alerts,
    })
}

export function* accountRetrievedWatcher() {
    yield takeEvery(ACCOUNT_RETRIEVED, listAlerts);
}