import React from "react";
import { Switch, Route } from "react-router-dom";
import LighthouseReportViewer from "views/lighthouse-report-viewer";

export const LighthouseReportLayout = () => {
  return (
    <Switch>
      <Route path="" component={LighthouseReportViewer} />
    </Switch>
  );
};

export default LighthouseReportLayout;
