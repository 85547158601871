import React, { useEffect, useState } from "react";
import Loading from "components/shared-components/Loading";
import AuthService from "services/AuthService";
import { useHistory } from "react-router-dom";

const ConfirmEmail = (props) => {
  let history = useHistory();
  const [confirmed, setConfirmed] = useState(false);
  const [confirmFailed, setConfirmFailed] = useState(false);

  useEffect(() => {
    if (!confirmed) {
      let key = props.location.pathname.split("/")[3];
      AuthService.verifyEmail(key)
        .then((data) => {
          if ((data.detail = "ok")) {
            setConfirmed(true);
          }
          if ((data.detail = "Not Found.")) {
            setConfirmFailed(true);
          }
        })
        .catch(() => {
          setConfirmFailed(true);
        });
    }

    if (confirmed) {
      setTimeout(() => {
        history.push("/auth/login");
      }, 2000);
    }

    if (confirmFailed) {
      setTimeout(() => {
        history.push("/auth/login");
      }, 5000);
    }
  });

  if (!confirmed && !confirmFailed) {
    return <Loading cover="content" />;
  }

  if (confirmed) {
    return <div>Your account has been successfully confirmed!</div>;
  }

  if (confirmFailed) {
    return (
      <div>
        Failed to confirm your account. Contact us at help@dtekt.io. Sorry for
        the inconvenience.
      </div>
    );
  }
};

export default ConfirmEmail;
