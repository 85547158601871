import React from "react";
import { Switch, Route } from "react-router-dom";
import ErrorViews from "views/error-views";

export const ErrorLayout = () => {
  return (
    <div className="auth-container">
      <Switch>
        <Route path="" component={ErrorViews} />
      </Switch>
    </div>
  );
};

export default ErrorLayout;
