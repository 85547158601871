import React from "react";
import TSDBService from "services/TSDBService";
import { Bold15, Bold, TooltipLegendMarker } from "components/shared-components/TooltipElements";
import Utils from "utils";
import { DataPoint } from "utils/Timeserie";
import { REQUESTS_SIZES_COLORS, MAIN_THREAD_BREAKDOWN_COLORS } from "constants/ColorConstant";
import { FLAGS } from 'constants/LocationConstant';
import { Timerange } from "utils/Time";

export type MetricConfig = {
    formatting: string
    display: string
    name: string
    tooltipMessageFormatter: (any)
}

export type MetricsConfig = { [index: string]: MetricConfig }

export default class TSDBController {
    accountId: string
    metricsConfig: any = null

    getFlag = (dataPoint: DataPoint) => {
        let flag = <></>
        if (dataPoint["labels"] && dataPoint["labels"]["location"]) {
            // @ts-ignore
            flag = FLAGS[dataPoint["labels"]["location"]].icon
            return (<>{flag}</>)
        }
    }

    formatters: {[index:string]: (any)} = {
        "uptime_check_ssl_cert_expiration_seconds": (dp: DataPoint) => (<>{this.getFlag(dp)} Time left until SSL certificate expiration: <Bold15>{Utils.daysToDhm(dp.y)}</Bold15></>),
        "uptime_check_connect_time_seconds": (dp: DataPoint) => (<>{this.getFlag(dp)} Time it took to establish TCP connection to the server: <Bold15>{dp.y} milliseconds</Bold15></>),
        "uptime_check_dns_time_seconds": (dp: DataPoint) => (<>{this.getFlag(dp)} Time spent resolving DNS: <Bold15>{dp.y} </Bold15></>),
        "uptime_check_size_download_bytes": (dp: DataPoint) => (<>{this.getFlag(dp)} Size of downloaded asset: <Bold15>{dp.y} kilobytes</Bold15></>),
        "uptime_check_redirect_time_seconds": (dp: DataPoint) => (<>{this.getFlag(dp)} Time spent redirecting: <Bold15>{dp.y} milliseconds</Bold15></>),
        "uptime_check_redirect_count": (dp: DataPoint) => (<>{this.getFlag(dp)} Number of performed redirects: <Bold15>{dp.y}</Bold15></>),
        "performance_check_largest_contentful_paint_seconds": (dp: DataPoint) => (<>{this.getFlag(dp)} Largest Contentful Paint: <Bold15>{Utils.round2(dp.y)}s</Bold15></>),
        "performance_check_first_contentful_paint_seconds": (dp: DataPoint) => (<>{this.getFlag(dp)} First Contentful Paint: <Bold15>{Utils.round2(dp.y)}s</Bold15></>),
        "performance_check_total_blocking_time_seconds": (dp: DataPoint) => (<>{this.getFlag(dp)} Total Blocking Time: <Bold15>{Utils.round2(dp.y)}s</Bold15></>),
        "performance_check_speed_index_seconds": (dp: DataPoint) => (<>{this.getFlag(dp)} Speed Index: <Bold15>{Utils.round2(dp.y)}s</Bold15></>),
        "performance_check_time_to_interactive_seconds": (dp: DataPoint) => (<>{this.getFlag(dp)} Time To Interactive: <Bold15>{Utils.round2(dp.y)}s</Bold15></>),
        "performance_check_cumulative_layout_shift": (dp: DataPoint) => (<>{this.getFlag(dp)} Layout Shifts: <Bold15>{dp.y}</Bold15></>),
        "performance_check_assets_bytes": (dp: DataPoint) => {
            const mapping:{[index:string]: JSX.Element} = {
                "js": <><Bold>JavaScript</Bold> assets</>,
                "font": <><Bold>fonts</Bold></>,
                "css": <><Bold>CSS</Bold> assets</>,
                "other": <><Bold>other</Bold> assets</>,
                "html": <><Bold>HTML</Bold> assets</>
            }
            return (
                <>
                    <TooltipLegendMarker color={REQUESTS_SIZES_COLORS[dp.labels.kind]}></TooltipLegendMarker> {this.getFlag(dp)} Size of {mapping[dp.labels.kind]}: <Bold15>{Utils.round2(dp.y)}</Bold15> kilobytes <br />
                </>
            )
        },
        "performance_check_assets_requests": (dp: DataPoint) => {
            const mapping: { [index: string]: JSX.Element } = {
                "js": <><Bold>JavaScript</Bold> assets</>,
                "font": <><Bold>fonts</Bold></>,
                "css": <><Bold>CSS</Bold> assets</>,
                "other": <><Bold>other</Bold> assets</>,
                "html": <><Bold>HTML</Bold> assets</>
            }
            return (
                <>
                    <TooltipLegendMarker color={REQUESTS_SIZES_COLORS[dp.labels.kind]}></TooltipLegendMarker> {this.getFlag(dp)} Number of requests for {mapping[dp.labels.kind]}: <Bold15>{dp.y}</Bold15> <br />
                </>
            )
        },
        "performance_check_main_thread_breakdown_seconds": (dp: DataPoint) => {
            const mapping: { [index: string]: JSX.Element } = {
                "scriptEvaluation": <>Evaluating JS scripts</>,
                "styleLayout": <>CSS styling layout changes</>,
                "scriptParseCompile": <>Compiling and parsing JS scripts</>,
                "parseHTML": <>Parsing HTML scripts</>,
                "paintCompositeRender": <>Paiting</>,
                "garbageCollection": <>Garbage collection</>,
                "other": <>Other</>
            }

            return (
                <>
                    <TooltipLegendMarker color={MAIN_THREAD_BREAKDOWN_COLORS[dp.labels.kind]}></TooltipLegendMarker> {this.getFlag(dp)} {mapping[dp.labels.kind]}: <Bold15>{dp.y}</Bold15> milliseconds <br />
                </>
            )
        },
        "performance_check_lighthouse_score": (dp: DataPoint) => {
            const mapping: { [index: string]: JSX.Element } = {
                "pwa": <><Bold>Progressive Web App</Bold></>,
                "seo": <><Bold>SEO</Bold></>,
                "best_practices": <><Bold>Best Practices</Bold></>,
                "performance": <><Bold>Performance</Bold></>,
                "accessibility": <><Bold>Accessibility</Bold></>
            }
            return (
                <>
                    {this.getFlag(dp)} Lighthouse {mapping[dp.labels.kind]} score: <Bold15>{dp.y}</Bold15> <br />
                </>
            )
        }
    }

    constructor(accountId: string) {
        this.accountId = accountId
    }

    // List metrics for given account
    listMetrics = () => {
        return TSDBService.queryIndex(this.accountId).then((response: any) => {
            return response.metrics
        })
    }

    metricInfo = (metric: string) => {
        return TSDBService.metricInfo(this.accountId, metric)
    }

    fetchMetricsConfig = () => {
        if (this.metricsConfig !== null) {
            return Promise.resolve(this.metricsConfig)
        }
        return TSDBService.fetchMetricsConfig(this.accountId).then((metricsConfig:MetricsConfig) => {
            Object.keys(metricsConfig).forEach((name: string) => {
                if (name in this.formatters) {
                    metricsConfig[name]["tooltipMessageFormatter"] = this.formatters[name]
                } else {
                    metricsConfig[name]["tooltipMessageFormatter"] = (value: number) => (<>{name}: <Bold15>{value}</Bold15></>)
                }
            })

            this.metricsConfig = metricsConfig
        })
    }

    tokenizeQuery = (query: string) => {
        return TSDBService.tokenizeQuery(this.accountId, query)
    }

    query = (query: string, timerange: Timerange, retention: string) => {
        return TSDBService.query(this.accountId, query, timerange, retention)
    }

    queryAll = (queries: string[], timerange: Timerange, retention: string) => {
        return TSDBService.queryAll(this.accountId, queries, timerange, retention)
    }
}