import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import User from "./User";
import Account from "./Account";
import Perfmon from "./Perfmon";
import Upmon from "./Upmon";
import Alert from "./Alert";
import Browmon from "./Browmon";

const appReducer = combineReducers({
  theme: Theme,
  auth: Auth,
  user: User,
  account: Account,
  perfmon: Perfmon,
  upmon: Upmon,
  alert: Alert,
  browmon: Browmon,
});

const rootReducer = (state, action) => {
  if (action.type == "SIGNOUT_SUCCESS") {
    console.log("Cleaning up store")
    return appReducer(undefined, action)
  }

  if (action.type == "ACCOUNT_SWITCHED") {
    console.log("Cleaning up store")
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default rootReducer;
