import {
  USER_INFO_OBTAINED,
  USER_API_TOKENS_OBTAINED,
} from "../constants/User";

export const userInfoObtained = (userInfo) => ({
  type: USER_INFO_OBTAINED,
  userInfo,
});
export const userApiTokensObtained = (apiTokens) => ({
  type: USER_API_TOKENS_OBTAINED,
  apiTokens,
});
