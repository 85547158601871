import React from "react";
import { Popover } from 'antd';

const TrimmedText = (props) => {
    let { text, maxLength } = props;

    if (text.length <= maxLength) {
        return <span  className={props.className}>{text}</span>
    }

    let content = <div style={{ zIndex: 200 }}>{text}</div>

    return (
        <Popover content={content}>
            <span className={props.className}>{text.substr(0, maxLength)}...</span>
        </Popover>
    )
};

export default TrimmedText;
