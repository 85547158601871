export const LIGHT_GREY = "#edf2f9";

export const COLOR_CRIT = "#ff4181"; // red
export const COLOR_WARN = "#ffb200"; // orange
export const COLOR_OK = "#1de9b6"; // green
export const COLOR_GRAY = "#d6d6d6"; // gray
export const COLOR_UNKN = "#d6d6d6"; // gray

export const REQUESTS_SIZES_COLORS: { [index: string]: string } = {
    "css": "rgba(54, 162, 235, 1)",
    "html": "rgba(255, 99, 132, 1)",
    "js": "rgba(255, 205, 86, 1)",
    "other": "rgba(255, 0, 0, 1)",
    "font": "rgba(75, 192, 192, 1)"
}

export const MAIN_THREAD_BREAKDOWN_COLORS: { [index: string]: string } = {
    "scriptEvaluation": "rgba(255, 0, 0, 1)",
    "other": "rgba(118, 120, 237, 1)",
    "styleLayout": "rgba(255, 205, 86, 1)",
    "scriptParseCompile": "rgba(54, 162, 235, 1)",
    "parseHTML": "rgba(75, 192, 192, 1)",
    "paintCompositeRender": "rgba(48, 82, 82, 1)",
    "garbageCollection": "rgba(189, 178, 70, 1)"
}


export const LOCATION_COLORS: { [index: string]: string } = {
    "EU-1": "rgba(54, 162, 235, 1)",
    "NA-2": "rgba(255, 99, 132, 1)",
    "NA-1": "rgba(255, 205, 86, 1)",
    "EU-2": "rgba(255, 0, 0, 1)",
    "SA-1": "rgba(75, 192, 192, 1)"
}