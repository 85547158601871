import React from "react";
import { Redirect } from "react-router-dom";
import useUserInfo from "hooks/useUserInfo";

const withMinimumOneAccount = (WrappedComponent) => {
  const Hoc = (props) => {
    let userInfo = useUserInfo();
    const numAccounts = userInfo.accounts.length;
    const numInvites = userInfo.invitations.length;

    if (numAccounts === 0 && props.location.pathname != "/init/invites" && numInvites == 0) {
      return <Redirect to="/init/create-account" />;
    }

    return <WrappedComponent {...props} />;
  };
  return Hoc;
};

export default withMinimumOneAccount;
