import React, { useEffect, useState } from "react";
import PerfmonService from "services/PerfmonService";
import useAccount from "hooks/useAccount";
import Loading from "components/shared-components/Loading";

const LighthouseReportViewer = (props: any) => {
    let [reportURL, setReportURL] = useState("")
    let account = useAccount();
    let ts = props.match.params.timestamp
    let monitorUuid = props.match.params.monitorUuid

    useEffect(() => {
        if (reportURL == "") {
            PerfmonService.getLighthouseReport(account.id, monitorUuid, ts).then((report: any) => {
                setReportURL(atob(report.url))
            })
        }
    })

    if (reportURL === "") {
        return <Loading />
    }

    return (
        <iframe frameBorder="0" height="1000" width="100%" src={reportURL}></iframe>
    )
}

export default LighthouseReportViewer;