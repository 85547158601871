import fetch from "auth/FetchInterceptor";
const AccountService = {};

AccountService.get = function (id) {
  /*
   *  Gets an account by id
   */
  return fetch({
    url: `/account/${id}/`,
    method: "get",
  });
};

AccountService.getTimeseriesToken = function (id) {
  /*
   *  Gets a token granting access to Timeseries DB
   */
  return fetch({
    url: `/account/${id}/timeseries-token`,
    method: "get"
  });
};


AccountService.create = function (data) {
  /*
   * Create an account
   */
  return fetch({
    url: `/account/`,
    method: "post",
    data,
  });
};

AccountService.getUsers = function (id) {
  /*
   * Create an account
   */
  return fetch({
    url: `/account/${id}/users/`,
    method: "get",
  });
};

AccountService.makeAdmin = function (accountId, userId) {
  /*
   * Makes user an admin
   */
  return fetch({
    url: `/account/${accountId}/admins`,
    method: "put",
    data: { user_id: userId },
  });
};

AccountService.deleteUser = function (accountId, userId) {
  console.log(`Deleting user ${userId} from account ${accountId}`);
  // return fetch({
  //     url: `/account/${accountId}/users/${userId}/`,
  //     method: 'delete'
  // })
};

AccountService.getPerformanceMonitors = function (accountId) {
  return fetch({
    url: `/account/${accountId}/performance-monitors/`,
    method: "get",
  });
};

AccountService.getBrowserMonitors = function (accountId) {
  return fetch({
    url: `/account/${accountId}/browser-monitors/`,
    method: "get",
  });
};

AccountService.getPerformanceMonitorTimeseries = function (
  accountId,
  monitorUuid,
  timerange
) {
  let { from, to } = timerange.isoString()

  return fetch({
    url: `/account/${accountId}/performance-monitors/${monitorUuid}/timeseries`,
    method: "get",
    params: {
      date_from: from,
      date_to: to,
    },
  });
};

AccountService.getUptimeMonitorLatestChecks = function (
  accountId,
  monitorUuid
) {
  return fetch({
    url: `/account/${accountId}/uptime-monitors/${monitorUuid}/latestchecks`,
    method: "get",
    params: { amount: 5 },
  });
};

AccountService.getUptimeMonitorCheckDetails = function (
  accountId,
  monitorUuid,
  requestTimestamp,
  status
) {
  return fetch({
    url: `/account/${accountId}/uptime-monitors/${monitorUuid}/static?requestTimestamp=${requestTimestamp}&status=${status}`,
    method: "get",
  });
};

AccountService.getUptimeMonitorOutage = function (
  accountId,
  monitorUuid,
  requestTimestamp,
  requestsCount
) {
  return fetch({
    url: `/account/${accountId}/uptime-monitors/${monitorUuid}/outage?requestTimestamp=${requestTimestamp}&requestsCount=${requestsCount}`,
    method: "get",
  });
};

AccountService.inviteUserByEmail = function (accountId, email) {
  return fetch({
    url: `/account/${accountId}/invites/`,
    method: "post",
    data: { email: email },
  });
};

AccountService.deleteUserById = function (accountId, userId) {
  return fetch({
    url: `/account/${accountId}/users/${userId}`,
    method: "delete",
  });
};

AccountService.deletePerformanceMonitor = function (accountId, monitorUuid) {
  return fetch({
    url: `/account/${accountId}/performance-monitors/${monitorUuid}/`,
    method: "delete",
    data: { run_in_background: true },
  });
};

AccountService.getPerformanceMonitorDomains = function (
  accountId,
  monitorUuid,
  timerange
) {
  let { from, to } = timerange.isoString()

  return fetch({
    url: `/account/${accountId}/performance-monitors/${monitorUuid}/domains`,
    method: "get",
    params: {
      date_from: from,
      date_to: to
    },
  });
};

AccountService.getBillingDetails = function (accountId) {
  return fetch({
    url: `/account/${accountId}/billing-details/`,
    method: "get",
  });
};

AccountService.putBillingDetails = function (accountId, data) {
  return fetch({
    url: `/account/${accountId}/billing-details/`,
    method: "put",
    data: data,
  });
};

AccountService.acceptInvite = (data) => {
  return fetch({
    url: "/account/invites/accept",
    method: "post",
    data,
  });
}

AccountService.cancelInvite = (accountId, email) => {
  return fetch({
    url: `/account/${accountId}/invites/`,
    method: "delete",
    data: { "email": email }
  });
}

AccountService.patchSettings = function (accountId, userId, settings) {
  return fetch({
    url: `/account/${accountId}/user/${userId}/settings/`,
    method: "patch",
    data: settings
  });
};

AccountService.getEvents = function (accountId, timerange, eventTypes=[]) {
  let { from, to } = timerange.isoString()

  let url = `/account/${accountId}/event?date_from=${from}&date_to=${to}`

  if (eventTypes.length > 0) {
    eventTypes.forEach((type) => {
      url += `&type=${type}`
    })
  }

  return fetch({ url })
}

AccountService.getReportLink = function (accountId, generationTime, reportUuid, reportType) {
  let url = `/account/${accountId}/report?generation_time=${generationTime}&report_uuid=${reportUuid}&report_type=${reportType}`
  return fetch({ url })
}

AccountService.getIntegrations = function (id) {
  /*
   *  Gets integrations with status
   */
  return fetch({
    url: `/account/${id}/integrations`,
    method: "get",
  });
};


export default AccountService;
