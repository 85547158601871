import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { GradientSteps, Serie, GradientStep } from "utils/Timeserie";
import { COLOR_OK, COLOR_WARN, COLOR_CRIT } from "constants/ColorConstant";
import { MetricConfig } from "controllers/TSDBController";
import { Card, Row, Col, Divider } from "antd";
import { Timestamp } from "components/shared-components/TooltipElements"
import { Formatters } from 'utils';

// A builder for ChartJS Charts
export default class ChartBuilder {
    // Basic settings
    responsive: boolean = true
    maintainAspectRatio: boolean = false

    // Plugins
    legendDisplay:true | null = null
    legendPosition:string = "bottom"

    tooltip: object = {
        enabled: true
    }

    // Axes
    xMin: number = 0
    xMax: number = 0
    xType: string = 'time'
    xTimeUnit: string | null = 'hour'
    xGridDisplay: boolean = true
    xStacked: boolean = false

    yMin: number | undefined = undefined
    yMax: number | undefined = undefined
    yBeginAtZero: boolean = true
    yGridDisplay: boolean = true
    yAxisTitle: any = undefined
    yStacked: boolean = false

    onHover: null | (any) = null
    onClick: (any) = () => null

    annotation: any = null
    autocolors: boolean = false
    interactionMode: string = "nearest"

    animation: boolean = false

    withExternalTooltip = (position: string, enabled: boolean, handler: any) => {
        this.tooltip = {
            position,
            enabled,
            external: handler
        }
        return this
    }

    withDefaultDtektTooltip = (mc: MetricConfig) => {
        const tooltipHandler = (context:any) => {
            if (mc === undefined) {
                console.error("[defaultDtektTooltip] Missing metricConfig")
                return
            }
            const { chart, tooltip } = context;
            const tooltipEl = GetOrCreateTooltip(chart)
            // Hide if no tooltip
            if (tooltip.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
            }

            // Query current point in first dataset just to get current time
            // to display in tooltip
            let idx = chart.tooltip.dataPoints[0]['dataIndex'];
            let dataPoint = chart.tooltip.dataPoints[0]['dataset']['data'][idx];

            // let location = chart.tooltip.dataPoints[0]
            tooltipEl.innerHTML = ReactDOMServer.renderToStaticMarkup(
                <Card>
                    <Row>
                        <Col span={24} style={{ textAlign: "left" }}>
                            <Timestamp>{Formatters.tz(dataPoint.x)}</Timestamp>
                        </Col>
                    </Row>
                    <Divider style={{ marginBottom: "10px", marginTop: "5px" }} />
                    <Row>
                        <Col>
                            {
                                chart.tooltip.dataPoints.map((point: any) => {
                                    const idx = point['dataIndex']
                                    const dataPoint = point['dataset']['data'][idx]
                                    return mc.tooltipMessageFormatter(dataPoint)
                                })
                            }

                        </Col>
                    </Row>
                </Card>
            )
    
            const boundingRect = chart.canvas.getBoundingClientRect();
            const { offsetTop: positionY } = chart.canvas;

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = 'absolute';

            if (tooltip.caretX < boundingRect.width / 2) {
                tooltipEl.style.left = 40 + window.pageXOffset + tooltip.caretX + "px";
                tooltipEl.style.top = positionY + tooltip.caretY + 10 + 'px';
            } else {
                tooltipEl.style.left = -385 + window.pageXOffset + tooltip.caretX + "px";
                tooltipEl.style.top = positionY + tooltip.caretY + 10 + 'px';
            }

            tooltipEl.style.zIndex = '1337';
            tooltipEl.style.pointerEvents = 'none';            
        }

        this.tooltip = {
            position: "nearest",
            enabled: false,
            external: tooltipHandler
        }
        return this
    }

    withMetricsConfig = (mc: MetricConfig) => {
        if (mc === undefined) {
            console.error("[withMetricsConfig] Missing metricConfig")
            return this
        }
        this.withYAxisTitle(mc.display)
        return this
    }

    withProp = (name: string, value: number | string | boolean) => {
        // @ts-ignore
        this[name] = value
        return this
    }

    withClickableMarker = () => {
        this.onHover = (event:any, activeEvents:any) => {
            (event?.native?.target as HTMLElement).style.cursor = activeEvents?.length > 0 ? 'pointer' : 'auto';
        }

        return this
    }

    withOnclick = (callback:(any)) => {
        this.onClick = callback
        return this
    }

    withYAxisTitle = (title: string) => {
        this.yAxisTitle = {
            display: true,
            text: title,
            font: {
                size: 16
            }
        }
        return this
    }

    withAnnotations = (annotations: any) => {
        this.annotation = {
            "annotations": annotations
        }
        return this
    }

    fillAboveY = (yMin:number, yMax: number, color: string) => {
        let box: AnnotationBox = {
            xMin: this.xMin,
            xMax: this.xMax,
            yMin: yMin,
            yMax: yMax,
            type: 'box',
            backgroundColor: color,
            borderWidth: 0
        }

        if (this.annotation === null) {
            let boxes: { [index: string]: AnnotationBox } = {}
            boxes[`box_${box.xMin}_${box.xMax}_${box.yMin}_${box.yMax}}`] = JSON.parse(JSON.stringify(box))
            return this.withAnnotations(boxes)
        } else {
            let boxes = this.annotation.annotations
            boxes[`box_${box.xMin}_${box.xMax}_${box.yMin}_${box.yMax}}`] = JSON.parse(JSON.stringify(box))
            return this.withAnnotations(boxes)
        }
    }

    assemble = () => {
        let assembled = {
            responsive: this.responsive,
            maintainAspectRatio: this.maintainAspectRatio,
            plugins: {
                autocolors: this.autocolors,
                legend: {
                    display: this.legendDisplay,
                    position: this.legendPosition,
                    labels: {
                        boxWidth: 12,
                        font: {
                            size: 12
                        }
                    }
                },
                tooltip: this.tooltip,
                annotation: this.annotation
            },
            onHover: this.onHover,
            onClick: this.onClick,
            animation: this.animation,
            interaction: {
                intersect: false,
                mode: this.interactionMode,
            },
            scales: {
                x: {
                    stacked: this.xStacked,
                    min: this.xMin,
                    max: this.xMax,
                    type: this.xType,
                    time: {
                        unit: this.xTimeUnit
                    },
                    grid: {
                        display: this.xGridDisplay,
                    }
                },
                y: {
                    stacked: this.yStacked,
                    beginAtZero: this.yBeginAtZero,
                    grid: {
                        display: this.yGridDisplay,
                    },
                    title: this.yAxisTitle
                }
            }
        }

        if (this.yMax !== undefined) {
            // @ts-ignore
            assembled.scales.y.max = this.yMax
        }
        if (this.yMin !== undefined) {
            // @ts-ignore
            assembled.scales.y.min = this.yMin
        }

        return assembled
    }
}

export const GetOrCreateTooltip = (chart:any) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');
    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        chart.canvas.parentNode.appendChild(tooltipEl);
    }
    return tooltipEl;
}

type AnnotationBox = {
    xMin: number
    xMax: number
    yMin: number
    yMax: number
    type: string
    backgroundColor: string
    borderWidth?: number
}

export const GenerateAnnotationBoxes = (failSerie: Serie, yMax: number | null) => {
    let points = failSerie.data

    let boxes: { [index: string]: AnnotationBox } = {}

    let outageMode = false
    let tempBox: AnnotationBox
    let length = points.length

    if (yMax === null) {
        yMax = 1
    }

    points.forEach((point: any, index: number) => {
        // This if opens a new annotation when we come across failed check
        // When such check is failed we enter "outage mode" and the below if
        // will then close this box once a next successful check is found.
        if (point[1] > 0 && !outageMode) {
            outageMode = true
            tempBox = {
                xMin: point[0],
                xMax: point[0],
                yMin: 0,
                // We chage yMax from null to 1 if it is null
                // so we can ignore the error.
                // @ts-ignore
                yMax: yMax,
                type: 'box',
                backgroundColor: 'rgba(255, 99, 132, 0.25)'
            }
        }

        // This if closes an annotation when we come across successful check in outageMode
        // The second condition `index === length - 1 && outageMode` makes sure
        // that if we are on the last point, and the outage is still occuring
        // we mark the annotation until the last point in chart. Otherwise the box
        // would never be "closed" and insterted into boxes map.
        if (point[1] === 0 && outageMode || index === length - 1 && outageMode) {
            tempBox.xMax = point[0]
            boxes[`box_${tempBox.xMin}_${tempBox.xMax}`] = JSON.parse(JSON.stringify(tempBox))
            outageMode = false
        }
    })

    return boxes
}

export const GetCritWarnGradient = (context: any, criticalThreshold: number, warningThreshold: number, direction: string) => {
    const chart = context.chart;
    const { ctx, chartArea, scales } = chart;

    if (!chartArea) {
        return null;
    }

    let gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);

    const critical = (scales.y.getPixelForValue(criticalThreshold) - chartArea.top) / chartArea.height;
    const warning = (scales.y.getPixelForValue(warningThreshold) - chartArea.top) / chartArea.height;

    gradient.addColorStop(1, COLOR_OK);

    if (warning > 0) {
        gradient.addColorStop(warning, COLOR_WARN);
        gradient.addColorStop(warning, COLOR_OK);
    }

    if (critical > 0) {
        gradient.addColorStop(critical, COLOR_CRIT);
        gradient.addColorStop(critical, COLOR_WARN);
    }

    // if (direction === "desc") {
    //     gradient.addColorStop(criticalThreshold, COLOR_CRIT);
    //     gradient.addColorStop(warningThreshold, COLOR_WARN);
    //     gradient.addColorStop(0, COLOR_OK);
    // } else {
    //     gradient.addColorStop(warningThreshold, COLOR_WARN);
    //     gradient.addColorStop(criticalThreshold, COLOR_CRIT);
    //     gradient.addColorStop(0, COLOR_OK);
    // }

    return gradient
}

export const Gradient = (steps: GradientSteps) => (
    function (context: any) {
        const chart = context.chart;
        const { ctx, chartArea } = chart;

        if (!chartArea) {
            // This case happens on initial chart load
            return null;
        }

        let gradient: any = null;

        gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        steps.forEach((step: GradientStep) => (
            gradient.addColorStop(step[0], step[1])
        ))

        return gradient
    }
)