import store from "redux/store";
import { API_BASE_URL } from "configs/AppConfig";
import { notification } from "antd";
import AuthService from "services/AuthService";

const REFRESH_TOKEN_URL = `${API_BASE_URL}/rest-auth/token/refresh/`;
const AUTH_REDIRECT_LS_PATH = "AUTH_REDIRECT"

export default class AuthController {
    // Method that when site loads up is able to determine
    // if user is logged in. The only way this can determined
    // is by querying some API endpoint, in our case, it is /user/ 
    // If this call returns 200 then it means that the auth cookies
    // are still not expired and user remains logged in. If this endpoint
    // returns an error we then assume user to be logged of.
    //
    // requestedLocation is URL requested by the user which, potentially,
    // is in protected areas of appliacation (which require Login).
    // If user is logged in we can redirect him straight there. Otherwise 
    // We must remember this location and perform redirect after user logs in.
    isUserLoggedIn = (requestedLocation:any) => (
        fetch(REFRESH_TOKEN_URL, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow",
            referrer: "no-referrer",
        })
        .then((response) => {
            if (response && response.status === 200) {
                store.dispatch({ type: "AUTHENTICATED" })
            } else {
                if (
                    requestedLocation.pathname.startsWith("/app/apps/") || 
                    requestedLocation.pathname.startsWith("/app/pages/")
                ) {
                    AuthController.setAuthRedirect(requestedLocation)
                    notification.info({ message: "Please login first. You will be automatically redirected to desired location."})
                }
                store.dispatch({ type: "UNAUTHENTICATED" })
            }
        })
    )

    static setAuthRedirect = (object:any) => (
        localStorage.setItem(AUTH_REDIRECT_LS_PATH, JSON.stringify(object))
    )

    static resetAuthRedirect = () => (
        localStorage.removeItem(AUTH_REDIRECT_LS_PATH)
    )

    static getAuthRedirect = () => {
        let data = localStorage.getItem(AUTH_REDIRECT_LS_PATH)
        if (data === null) {
            return data
        }
        return JSON.parse(data)
    }

    static reset = () => {
        AuthService.resetCookies().then(() => {
            // We are dispatching SIGNOUT_SUCCESS because this
            // forces the redux to empty the state
            // LocalStorage is being cleaned in the
            // SIGNOUT_SUCCESS reducer
            store.dispatch({ type: "SIGNOUT_SUCCESS" })
            window.location.href = "/auth/login";
            window.location.reload()
        })
    }
}