import React from "react";
import { Spin } from "antd";
import PropTypes from "prop-types";

const Loading = (props) => {
  const { align, cover, size } = props;
  return (
    <div className={`loading text-${align} cover-${cover}`}>
      <Spin indicator={<img
        style={{ fontSize: size }}
        className="anticon-spin"
        src="/img/dtekt_spin.svg"
      />} />
    </div>
  );
};

Loading.propTypes = {
  size: PropTypes.string,
  cover: PropTypes.string,
  size: PropTypes.number
};

Loading.defaultProps = {
  align: "center",
  cover: "inline",
  size: 30
};

export default Loading;
