import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "components/shared-components/Loading";
import AuthService from "services/AuthService";
import { showAuthMessage, authenticated } from "redux/actions/Auth";
import RedirectController, { GOOGLE_AUTH_CALLBACK } from "utils/RedirectController";


class GoogleCallback extends Component {
  componentDidMount() {
    const { authenticated } = this.props;

    const params = new URLSearchParams(this.props.location.hash);
    var id_token = params.get("id_token");

    let rc = new RedirectController(GOOGLE_AUTH_CALLBACK)

    if (!id_token) {
      return this.props.history.push("/");
    }

    AuthService.loginGoogle({ access_token: id_token }).then((res) => {
        authenticated(res);
        return this.props.history.push(rc.redirect);
      })
  }

  render() {
    return <Loading cover="content" />;
  }
}

const mapDispatchToProps = {
  authenticated,
};

export default connect(null, mapDispatchToProps)(GoogleCallback);
