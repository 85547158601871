export const GOOGLE_AUTH_CALLBACK = "GOOGLE_AUTH_CALLBACK"
export const CHOOSE_ACCOUNT = "CHOOSE_ACCOUNT"

export const INVITE_PATH = "/auth/invite"
export const CHOOSE_ACCOUNT_PATH = "/init/choose-account"

type PredefinedPath = "GOOGLE_AUTH_CALLBACK" | "CHOOSE_ACCOUNT"

// This is an controller that allows frontend to perform
// important actions like accepting invites or properly
// redirecting to ?next= page

// The idea is that any place in the app can create instance of this
// controller constructing it with its current location and the current search param
// and this controller should give the answer what should be next redirect.
export default class RedirectController {
    search: string
    currentPath: string | PredefinedPath

    constructor(currentPath: string | PredefinedPath, search: string) {
        this.currentPath = currentPath
        this.search = search
    }

    constructRedirect = (path: string, search: string) => {
        return `${path}${search}`
    }

    public get redirect(): string | null {
        const params = new URLSearchParams(this.search);

        if (this.currentPath === GOOGLE_AUTH_CALLBACK) {
            // App is currently in Google login callback. We have two things
            // to do here. Check if inviteKey is in the params, if yes then 
            // redirect user to the /auth/invite where invitation will be
            // accepted.
            if (params.get("inviteKey")) {
                return this.constructRedirect(INVITE_PATH, this.search)
            }
            // Redirect user to choose-account URL where it will have the account
            // automatically set for him. Preserve search as it may contain ?next=
            if (params.get("accountId")) {
                return this.constructRedirect(CHOOSE_ACCOUNT_PATH, this.search)
            }

            return "/init/choose-account"
        }

        if (this.currentPath === CHOOSE_ACCOUNT) {
            let next = params.get("next")
            if (next) {
                params.delete("next")
                params.delete("accountId")

                let newSearch;
                if (params.toString().length === 0) {
                    newSearch = ""
                } else {
                    newSearch = "?" + params.toString()
                }

                return this.constructRedirect(next, newSearch)
            }

            return null
        }

        return null
    }
}