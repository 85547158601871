import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import GoogleCallback from "./authentication/GoogleCallback";
import ConfirmEmail from "./authentication/ConfirmEmail";
import ProcessInvite from "./invite/ProcessInvite";
import { setRedirect } from "redux/actions/Auth";
import { connect } from "react-redux";

export const AppViews = ({ match }) => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route
          path={`${match.url}/login/google_oauth2/callback`}
          component={GoogleCallback}
        />
        <Route
          path={`${match.url}/invite/`}
          component={ProcessInvite}
        />
        <Route path={`${match.url}/confirm-email`} component={ConfirmEmail} />
        <Route
          path={`${match.url}/login`}
          component={lazy(() => import(`./authentication/login`))}
        />
        <Route
          path={`${match.url}/register`}
          component={lazy(() => import(`./authentication/register-1`))}
        />
        <Redirect from={`${match.url}`} to={`${match.url}/login`} />
      </Switch>
    </Suspense>
  );
};

const mapDispatchToProps = {
  setRedirect,
};

export default connect(null, mapDispatchToProps)(AppViews);
