import React, { useRef } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import Views from "./views";
import { Route, Switch } from "react-router-dom";
import TourContextProvider from "context/TourContext";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <TourContextProvider>
          <Router>
            <Switch>
              <Route path="/" component={Views} />
            </Switch>
          </Router>
        </TourContextProvider>
      </Provider>
    </div>
  );
}

export default App;
