import React from "react";
import { Layout, Grid } from "antd";
import { Switch, Route } from "react-router-dom";
import InitViews from "views/init-views";
import HeaderNav from "components/layout-components/HeaderNav";
import utils from "utils";
const { useBreakpoint } = Grid;

export const InitLayout = () => {
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes("lg");

  return (
    <Layout>
      <HeaderNav isMobile={isMobile} toggleEnabled={false} />
      <Layout className="app-container">
        <Layout className="app-layout">
          <Switch>
            <Route path="" component={InitViews} />
          </Switch>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default InitLayout;
