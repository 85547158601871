import fetch from "auth/FetchInterceptor";

let StarterService: {
    getCredentials: (any),
    convert: (any)
}

StarterService = {
    convert: (data:any) => {
        return fetch({
            url: "/starter/convert/",
            method: "post",
            data,
        })
    },
    getCredentials: (data:any) => {
        return fetch({
            url: "/starter/credentials/",
            method: "post",
            data,
        })
    }
};

export default StarterService;
