import React, { useState, useContext } from 'react';
import { TourContext } from 'context/TourContext';
import { Tour } from 'antd';
import { Emoji } from 'emoji-mart';
import Utils from "utils";
import type { TourProps } from 'antd';
import useAccount from 'hooks/useAccount';

const SandboxTour = () => {
    let tourContext = useContext(TourContext)
    const account = useAccount();
    const [open, setOpen] = useState(true);

    const [sandboxCopyLinkRef, setSandboxCopyLinkRef] = useState<{[index: string]: any}>();
    const [sandboxAccountClaimRef, setSandboxAccountClaimRef] = useState<{ [index: string]: any }>();

    if (account === null || !Utils.isSandbox()) {
        return <></>
    }

    const steps: TourProps['steps'] = [
        {
            title: (
                <span>
                    <h3><span className="mr-2"><Emoji emoji=':wave::skin-tone-1:' size={18} /></span>Welcome to the sandbox account</h3>
                </span>
            ),
            description: (
                <p>
                    Welcome to your personalized demonstration account for DTEKT.IO, created <span style={{ fontWeight: 700 }}>exclusively for you</span>. This account is designed to allow you to explore and test our services. To begin, we invite you to take a brief guided tour to familiarize yourself with the sandbox.
                </p>
            ),
            target: null,
            nextButtonProps: {
                onClick: () => {
                    if (tourContext && tourContext.sandboxCopyLinkRef) {
                        setSandboxCopyLinkRef(tourContext.sandboxCopyLinkRef);
                    }
                },
            },
        },
        {
            title: (
                <span>
                    <h3>Sandbox sharing link</h3>
                </span>
            ),
            description: (
                <p>
                    This sandbox account can be accessed via a unique link. You may share this link with others by simply sending it to them. To copy the sandbox link to your clipboard, use the highlighted button.
                </p>
            ),
            nextButtonProps: {
                onClick: () => {
                    if (tourContext && tourContext.sandboxAccountClaimRef) {
                        setSandboxAccountClaimRef(tourContext.sandboxAccountClaimRef);
                    }
                },
            },
            target: sandboxCopyLinkRef?.current,
        },
        {
            title: (
                <span>
                    <h3>Convert sandbox to a regular account</h3>
                </span>
            ),
            description: (
                <p>
                    Are you interested in using DTEKT.IO? You can easily claim a sandbox account and upgrade it to a regular account. Simply click the highlighted button to get started.
                </p>
            ),
            target: sandboxAccountClaimRef?.current,
        },
        {
            title: (
                <span>
                    <h3>It is only temporary!</h3>
                </span>
            ),
            description: (
                <>
                    <p>Please note that this sandbox account has a limited lifespan and will be deactivated after two days. For a permanent account, we recommend converting this sandbox to a regular account or <a href="/auth/register">signing up</a> and setting up a new, regular, DTEKT.IO account.</p>
                    <p>
                        If you have any feedback don't hesitate to contact us using the <a href="/app/pages/feedback">feedback form</a>.
                    </p>
                </>
            ),
            target: null,
        },
    ];

    return (
        <Tour 
            open={open}
            steps={steps}
            onClose={() => setOpen(false)}
        />
    );
}

export default SandboxTour

