import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import withMinimumOneAccount from "hoc/withMinimumOneAccount";
import withAccountChosen from "hoc/withAccountChosen";
import { INITIAL_SCREEN } from "configs/AppConfig";
import { connect } from 'react-redux';


function RouteAdminOrOwnerInterceptor({ children, accountId, userInfo, ...rest }) {
  let nameToLook = `${accountId}_admins`;
  let isAdmin = false;

  userInfo.roles.forEach((role) => {
    if (role.name === nameToLook) {
      isAdmin = true
    }
  });

  const renderFn = ({ location }) => {
    if (isAdmin) {
      return children;
    } else {
      return (
        <Redirect
          to={{
            pathname: "/app",
            state: { from: location },
          }}
        />
      );
    }
  };

  return <Route {...rest} render={renderFn} />;
}

export const AdminViews = (props) => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <RouteAdminOrOwnerInterceptor path={`${props.match.url}/subscriptions`} accountId={props.account.id} userInfo={props.userInfo}>
          <Route
            path={`${props.match.url}/subscriptions`}
            component={lazy(() => import(`./subscriptions`))}
          />
        </RouteAdminOrOwnerInterceptor>
        <Redirect from={`${props.match.url}`} to={`${INITIAL_SCREEN}`} />
      </Switch>
    </Suspense>
  );
};

const mapStateToProps = ({ account, user }) => {
  return {
    account: account.account,
    userInfo: user.userInfo
  };
};

export default withMinimumOneAccount(withAccountChosen(connect(mapStateToProps, null)(AdminViews)));
