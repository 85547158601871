import React, { useRef } from "react";

type TourContextProps = {
    sandboxCopyLinkRef: any
    sandboxAccountClaimRef: any
}
export const TourContext = React.createContext<TourContextProps | null>(null);

type TourContextProviderProps = {
    children: React.ReactNode
}
const TourContextProvider = (props: TourContextProviderProps) => (
    <TourContext.Provider value={{
        sandboxCopyLinkRef: useRef(),
        sandboxAccountClaimRef: useRef()
    }}>
        {props.children}
    </TourContext.Provider>
)

export default TourContextProvider;