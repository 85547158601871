
import React, { useContext, useState } from 'react';
import { TourContext } from 'context/TourContext';
import { Menu, Tooltip, Tour, Input, notification } from 'antd';
import { connect, useDispatch } from "react-redux";
import {
    ShareAltOutlined,
    CheckOutlined,
    SyncOutlined
} from '@ant-design/icons';
import Utils from "utils";
import StarterService from 'services/StarterService';
import useAccount from 'hooks/useAccount';
import type { TourProps } from 'antd';
import AuthService from 'services/AuthService';
import { useHistory } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { COLOR_CRIT } from 'constants/ColorConstant';
import { COLOR_OK } from 'constants/ChartConstant';

const NavShareSandbox = () => {
    let dispatch = useDispatch()
    let history = useHistory();
    let [ copied, setCopied ] = useState(false);
    
    const [convertTourOpen, setConvertTourOpen] = useState<boolean>(false);
    const [desiredAccountName, setDesiredAccountName] = useState("")
    const [emailAddress, setEmailAddress] = useState("")
    const [loading, setLoading] = useState(false);

    let account = useAccount();
    
    let tourContext = useContext(TourContext)
    if (account === null || !Utils.isSandbox()) {
        return <></>
    }

    const copyToClipboard = () => {
        let sharingLink = Utils.getSandboxSharingLink()
        if (sharingLink !== undefined) {
            navigator.clipboard.writeText(sharingLink)
            setCopied(true)
            setTimeout(() => {
                setCopied(false)
            }, 1000)
        }
    }

    const getCopyLinkIcon = () => {
        if (copied) {
            return (
                <Tooltip title={"Sandbox link copied!"}>
                    <CheckOutlined className="nav-icon mx-auto" ref={tourContext!.sandboxCopyLinkRef} />
                </Tooltip>
            )
        }
        
        return (
            <Tooltip title={`Copy link to this sandbox`}>
                <ShareAltOutlined className="nav-icon mx-auto" ref={tourContext!.sandboxCopyLinkRef} />
            </Tooltip>
        )
    }

    const isCorrectEmail = () => {
        let expression = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        return expression.test(emailAddress);
    }

    const steps: TourProps['steps'] = [
        {
            title: (
                <span>
                    <h3>Convert sandbox to a regular account</h3>
                </span>
            ),
            description: (
                <>
                    <p>To initiate the process, we kindly request that you provide us with a new name for this account:</p>
                    <Input placeholder="Name your account" value={desiredAccountName} onChange={(e: any) => setDesiredAccountName(e.target.value)} />
                </>
            ),
            nextButtonProps: {
                style: {
                    display: desiredAccountName.length > 0 ? "inline" : "none"
                },
            },
            target: null,
        },
        {
            title: (
                <span>
                    <h3>We need your email address</h3>
                </span>
            ),
            description: (
                loading ? <Loading /> : 
                <>
                    <p>To complete the account conversion process, we require your email address to send an invitation for account ownership transfer. Upon accepting the invitation, you will become the new account owner, and the sandbox account will be converted to a regular account.</p>
                    {isCorrectEmail() ? (
                        <div className="ml-1" style={{ color: COLOR_OK }}>Email is correct</div>
                    ) : (
                         <div className="ml-1" style={{ color: COLOR_CRIT }}>Please provide correct email address</div>
                    )}
                    <Input placeholder="Your email address" value={emailAddress} onChange={(e: any) => setEmailAddress(e.target.value)} />
                </>
            ),
            nextButtonProps: {
                style: {
                    display: emailAddress.length > 0 && isCorrectEmail() ? "inline" : "none"
                },
                onClick: () => {
                    setLoading(true);
                    let convertRequest = {
                        account_id: account.id,
                        new_account_name: desiredAccountName,
                        email: emailAddress
                    }
                    StarterService.convert(convertRequest).then((resp: any) => {
                        AuthService.logout().then(() => {
                            history.push("/auth/login")
                            notification.info({
                                message: `Invitation has been sent to ${emailAddress}. To initiate the process of converting starter account to a regular account please click that you will find in your inbox.`,
                                duration: 10,
                            })
                            dispatch({ type: "SIGNOUT_SUCCESS" })
                            setLoading(false);
                            setConvertTourOpen(false);
                        })
                    })
                }
            },
            target: null,
        }
    ];

    return (
        <>
            <Menu mode="horizontal">
                <Menu.Item onClick={() => copyToClipboard()} style={{ padding: 0 }}>
                    {getCopyLinkIcon()}
                </Menu.Item>
                <Menu.Item onClick={() => setConvertTourOpen(true)}>
                    <Tooltip title={`Convert this account to a regular account`}>
                        <SyncOutlined className="nav-icon mx-auto" ref={tourContext!.sandboxAccountClaimRef} />
                    </Tooltip>
                </Menu.Item>
            </Menu>
            <Tour
                open={convertTourOpen}
                steps={steps}
                onClose={() => {
                    if (emailAddress == "" || desiredAccountName == "") {
                        setEmailAddress("");
                        setDesiredAccountName("");
                        setConvertTourOpen(false);
                    }
                }}
            />
        </>

    );
}

const mapStateToProps = ({ theme }: any) => {
    const { sandboxModalActive } = theme;
    return { sandboxModalActive };
};

export default connect(mapStateToProps)(NavShareSandbox);

