import React from "react";
import { connect } from "react-redux";
import Loading from "components/shared-components/Loading";
import UserService from "services/UserService";
import { userInfoObtained } from "redux/actions/User";

const withUserInfo = (WrappedComponent) => {
  const hoc = (props) => {
    if (props.userInfo === null) {
      UserService.get().then((userInfo) => {
        props.userInfoObtained(userInfo);
      });
      return <Loading cover="content" />;
    }

    return <WrappedComponent {...props} userInfo={props.userInfo} />;
  };
  return connect(mapStateToProps, mapDispatchToProps)(hoc);
};

const mapDispatchToProps = {
  userInfoObtained,
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.userInfo,
  };
};

export default withUserInfo;
