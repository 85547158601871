import { all } from "redux-saga/effects";

import {
  accountRetrievedWatcher
} from "./AccountSaga";


export default function* rootSaga(getState:any) {
  yield all([
    accountRetrievedWatcher()
  ]);
}
