import {
  ACCOUNT_RETRIEVED,
  ACCOUNT_SWITCHED,
  ACCOUNT_CREATED,
  ACCOUNT_USERS_RETRIEVED,
  ACCOUNT_UPTIME_MONITORS_RETRIEVED,
  ACCOUNT_PERFORMANCE_MONITORS_RETRIEVED,
  ACCOUNT_BROWSER_MONITORS_RETRIEVED,
  ACCOUNT_UPTIME_MONITOR_TIMESERIES_RETRIEVED,
  ACCOUNT_UPTIME_MONITOR_LATEST_CHECKS_RETRIEVED,
  ACCOUNT_PERFORMANCE_MONITOR_TIMESERIES_RETRIEVED,
  ACCOUNT_PERFORMANCE_MONITOR_DOMAINS_RETRIEVED,
  ACCOUNT_UPTIME_MONITOR_CHECK_DETAILS_RETRIEVED,
  ACCOUNT_UPTIME_MONITOR_CHECK_LIST_RETRIEVED,
  ACCOUNT_BILLING_DETAILS_RETRIEVED
} from "../constants/Account";

export const accountSwitched = (account) => ({
  type: ACCOUNT_SWITCHED,
  account,
});
export const accountRetrieved = (account) => ({
  type: ACCOUNT_RETRIEVED,
  account,
});
export const accountCreated = (account) => ({ type: ACCOUNT_CREATED, account });
export const accountUsersRetrieved = (users) => ({
  type: ACCOUNT_USERS_RETRIEVED,
  users,
});
export const accountBillingDetailsRetrieved = (data) => ({
  type: ACCOUNT_BILLING_DETAILS_RETRIEVED,
  data,
});
export const accountUptimeMonitorsRetrieved = (uptimeMonitors) => ({
  type: ACCOUNT_UPTIME_MONITORS_RETRIEVED,
  uptimeMonitors,
});
export const accountPerformanceMonitorsRetrieved = (performanceMonitors) => ({
  type: ACCOUNT_PERFORMANCE_MONITORS_RETRIEVED,
  performanceMonitors,
});
export const accountUptimeMonitorTimeseriesRetrieved = (monitorUuid, data) => ({
  type: ACCOUNT_UPTIME_MONITOR_TIMESERIES_RETRIEVED,
  monitorUuid,
  data,
});
export const accountUptimeMonitorCheckDetailsRetrieved = (
  monitorUuid,
  data,
  requestTimestamp
) => ({
  type: ACCOUNT_UPTIME_MONITOR_CHECK_DETAILS_RETRIEVED,
  monitorUuid,
  data,
  requestTimestamp,
});
export const accountUptimeMonitorCheckListRetrieved = (
  monitorUuid,
  data,
  requestTimestamp
) => ({
  type: ACCOUNT_UPTIME_MONITOR_CHECK_LIST_RETRIEVED,
  monitorUuid,
  data,
  requestTimestamp,
});
export const accountUptimeMonitorLatestChecksRetrieved = (
  monitorUuid,
  data
) => ({
  type: ACCOUNT_UPTIME_MONITOR_LATEST_CHECKS_RETRIEVED,
  monitorUuid,
  data,
});
export const accountPerformanceMonitorTimeseriesRetrieved = (
  monitorUuid,
  data
) => ({
  type: ACCOUNT_PERFORMANCE_MONITOR_TIMESERIES_RETRIEVED,
  monitorUuid,
  data,
});
export const accountPerformanceMonitorDomainsRetrieved = (
  monitorUuid,
  data
) => ({
  type: ACCOUNT_PERFORMANCE_MONITOR_DOMAINS_RETRIEVED,
  monitorUuid,
  data,
});