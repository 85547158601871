import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import withUserInfo from "hoc/withUserInfo";

export const InitViews = (props) => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route
          path={`${props.match.url}/choose-account`}
          component={lazy(() => import(`./choose-account`))}
        />
        <Route
          path={`${props.match.url}/create-account`}
          component={lazy(() => import(`./create-account`))}
        />
      </Switch>
    </Suspense>
  );
};

export default withUserInfo(InitViews);
