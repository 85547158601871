import {
  AUTHENTICATED,
  UNAUTHENTICATED,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNOUT_SUCCESS,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
  SET_REDIRECT
} from "../constants/Auth";
import { ACCOUNT_ID } from "redux/constants/Account";
import { TSDB_TOKEN_PATH } from "auth/TSDBInterceptor";

const initState = {
  loading: false,
  message: "",
  showMessage: false,
  loggedOut: false,
  redirect: "",
  // isAuthed: null - not yet known if user is logged in or not
  // isAuthed: false - user logged of
  // isAuthed: true - user logged in
  isAuthed: null
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case UNAUTHENTICATED:
      return {
        ...state,
        isAuthed: false,
      };
    case AUTHENTICATED:
      // When user is logged in it is possible he is logging back
      // with existing invalid token. Let's remove it so new one
      // is generated.
      localStorage.removeItem(TSDB_TOKEN_PATH)
      return {
        ...state,
        loading: false,
        redirect: "",
        isAuthed: true,
      };
    case SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false,
      };
    case HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: "",
        showMessage: false,
      };
    case SIGNOUT_SUCCESS: {
      localStorage.removeItem(ACCOUNT_ID);
      localStorage.removeItem(TSDB_TOKEN_PATH);
      return {
        ...state,
        token: null,
        isAuthed: false,
        redirect: "/",
        loggedOut: true,
        loading: false,
      };
    }
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        token: action.token,
      };
    }
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
      return {
        ...state,
        loading: false,
        token: action.token,
      };
    }
    case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
      return {
        ...state,
        loading: false,
        token: action.token,
      };
    }
    case SET_REDIRECT: {
      return {
        ...state,
        redirect: action.redirectPath
      }
    }
    default:
      return state;
  }
};

export default auth;
