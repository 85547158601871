import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";

export const APP_NAME = "DTEKT";
export const API_BASE_URL = env.API_ENDPOINT_URL;

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "en",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "#ffffff",
  mobileNav: false
};

type Feature = {
  title: string
}

interface Pricing {
  id: string
  priceId: string | null
  productId: string | null
  title: string
  price: number
  duration: string
  buttonText: string
  btnLink: string
  features: Array<Feature>
}

export const Pricings: Array<Pricing> = [
  {
    id: "free-plan",
    priceId: null,
    productId: null,
    title: "One month free",
    price: 0,
    duration: "mo",
    buttonText: "Get started",
    btnLink: "",
    features: [
      { title: "1 Uptime Monitor" },
      { title: "1 Performance Monitor" },
      { title: "1 Account user" },
      { title: "Single region available" },
    ],
  },
  {
    id: "subscribe-starter-plan",
    priceId: env.STRIPE_STARTER_PRICE_ID,
    productId: env.STRIPE_STARTER_PROD_ID,
    title: "Starter",
    price: 9.99,
    duration: "mo",
    buttonText: "Get started",
    btnLink: "",
    // isActive: true,
    features: [
      { title: "8 Uptime Monitors" },
      { title: "2 Performance Monitors" },
      { title: "2 Account users" },
      { title: "Two regions available" },
    ],
  },
  {
    id: "subscribe-basic-plan",
    priceId: env.STRIPE_BASIC_PRICE_ID,
    productId: env.STRIPE_BASIC_PROD_ID,
    title: "Basic",
    price: 29.99,
    duration: "mo",
    buttonText: "Get started",
    btnLink: "",
    features: [
      { title: "25 Uptime Monitors" },
      { title: "8 Performance Monitors" },
      { title: "5 Account users" },
      { title: "All regions available" },
    ],
  },
  {
    id: "subscribe-premium-plan",
    priceId: env.STRIPE_PREMIUM_PRICE_ID,
    productId: env.STRIPE_PREMIUM_PROD_ID,
    title: "Premium",
    price: 69.99,
    duration: "mo",
    buttonText: "Get started",
    btnLink: "",
    features: [
      { title: "55 Uptime Monitors" },
      { title: "15 Performance Monitors" },
      { title: "Unlimited account users" },
      { title: "All regions available" },
    ],
  },
];

export const INITIAL_SCREEN = "/app/apps/uptime_monitors"