import {
  USER_INFO_OBTAINED,
  USER_API_TOKENS_OBTAINED,
} from "../constants/User";

const initState = {
  userInfo: null,
  apiTokens: [],
};

const user = (state = initState, action) => {
  switch (action.type) {
    case USER_INFO_OBTAINED:
      return {
        ...state,
        userInfo: action.userInfo,
      };
    case USER_API_TOKENS_OBTAINED:
      return {
        ...state,
        apiTokens: action.apiTokens,
      };
    default:
      return state;
  }
};

export default user;
