// This set of interceptors was written for accessing TSDB. TSDB Require
// an AUTH_TOKEN header present. This token is generated by our API. 
// Using these interceptors we make sure that:
//   - Request Interceptor: 
//     1. When token is not present in localStorage it is requested from API and inserted into request headers
//     2. When token is present in localStorage it is grabbed and inserted into request headers
//   - Response Interceptor:
//     1. When Frontend receives 401 from the TSDB it most likely means that the token has expired (15m expiration).
//        Using response interceptor we generate new token, insert it into localStorage, and retry the failed request.
import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import { ACCOUNT_ID } from "redux/constants/Account";
import { notification } from "antd";
import AccountService from "services/AccountService";
import AuthController from "controllers/AuthController";

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

const TSDB_TOKEN_PATH = "tsdb_token"

// Gets token from AccountService and stores it in localStorage
export async function getAndSetToken() {
  let accountId = localStorage.getItem(ACCOUNT_ID)

  // Technically a situation when getAndSetToken is used, while accountId
  // is not present in localStorage should never occured, unless someone
  // manually removes the account_id from localStorage. In such case,
  // we must force user to reload.
  if (!accountId) {
    return AuthController.reset()
  } else {
    return AccountService.getTimeseriesToken(accountId).then((token) => {
      localStorage.setItem(TSDB_TOKEN_PATH, token)
      return token
    })
  }
}

// API Request interceptor
service.interceptors.request.use(async function(config) {
    const tsdbToken = localStorage.getItem(TSDB_TOKEN_PATH);
    // Token not present in localStorage. We gonna fetch and set it.
    if (!tsdbToken) {
      // Improve error handling
      config.headers["AUTH_TOKEN"] = await getAndSetToken()
    }

    // Token present in localStorage. We gonna just return it.
    // If user came back to site after some time, on first request
    // TSDB will return that token is expired and `response` interceptor
    // should generate new, and renew the request.
    if (tsdbToken) {
      config.headers["AUTH_TOKEN"] = tsdbToken;
    }

    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: "Error",
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => (response.data),
  async function(error) {
    if (error.code === "ECONNABORTED") {
      Promise.reject({ timeout: true });
      return;
    }

    if (!error.response) {
      AuthController.reset()
      Promise.reject(error);
    }

    // Remove token and redirect
    if (error.response.status >= 400 && error.response.status !== 401) {
      Promise.reject(error);
      return;
    }

    // Attempt to refresh the TSDB token
    if (
      error.response.status === 401 &&
      error.config &&
      !error.config._retry
    ) {
      const originalReq = error.config;
      originalReq._retry = true;

      let token = await getAndSetToken()
      originalReq.headers["AUTH_TOKEN"] = token
      return Promise.resolve(axios(originalReq).then((response) => response.data))
    }
  }
);

export default service;
export { TSDB_TOKEN_PATH }