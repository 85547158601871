import axios from "auth/FetchInterceptor";

const UserService = {};

UserService.get = function () {
  return axios({
    url: "/user/",
    method: "get",
  });
};

UserService.getApiTokens = function () {
  return axios({
    url: "/user/settings/apitoken",
    method: "get",
  });
};

UserService.postApiToken = function (data) {
  return axios({
    url: "/user/settings/apitoken",
    method: "post",
    data,
  });
};

UserService.deleteApiToken = function (apiTokenUuid) {
  return axios({
    url: `/user/settings/apitoken/${apiTokenUuid}/`,
    method: "delete",
  });
};

UserService.register = function (data) {
  return axios({
    url: "/rest-auth/registration/",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data,
  });
};

UserService.login = function (data) {
  return axios({
    url: "/rest-auth/login/",
    method: "post",
    data,
  });
};

export default UserService;
